<template>
  <div class="login-view">
    <!-- HEADER -->
    <HeaderView title="登入" />
    <div>uid:{{ uid }}</div>
    <div>acc:{{ acc }}</div>
    <div>nick:{{ nick }}</div>
    <div>icon:{{ icon }}</div>
  </div>
</template>

<script>
// loginView1 : 測試登入 url
// call by App.vue
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "loginView",
  components: {
    HeaderView
  },
  data() {
    return {
      uid: "",
      acc: "",
      nick: "",
      url: "",
      icon: "0",
      userInfo: {}
    };
  },
  computed: {},
  created() {
    this.uid = "1078735";
    this.acc = "bmw7910";
    this.nick = "you478";
    this.icon = "0"
    this.pwd = "kk7788"

    this.userInfo.uid = "1078735";
    this.userInfo.acc = "bmw7910";
    this.userInfo.nick = "you478";
    this.userInfo.icon = "0";
    this.userInfo.pwd = "kk7788";
    this.url = this.$route.query.url;

    //store uid to store
    this.$store.dispatch("setUserInfo", this.userInfo);

    //除 299, 499 外, 其餘登入後都導到首頁
    if (
      this.url != "/month299" &&
      this.url != "/month499" &&
      this.url != "/buy"
    ) {
      this.url = "/home";
    }

    this.url = this.$tsURL(this.url);
    //router.replace 不會在 history 留下紀錄
    this.$router.replace(this.url);
  }
};
</script>

<style>
/* .login-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
} */
</style>
