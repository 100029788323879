export default {
  install(Vue, options) {
    Vue.prototype.$text = function() {
      //全域性函式
      alert("執行成功1");
    };

    Vue.prototype.$timeKey = function() {
      //yyyyMMddHH

      var date = new Date();
      var intMonth = date.getMonth() + 1;
      var intDate = date.getDate();
      var intHour = date.getHours();
      var strMonth = "";
      var strDate = "";
      var strHour = "";

      if (intMonth >= 1 && intMonth <= 9) {
        strMonth = "0" + intMonth.toString();
      } else {
        strMonth = intMonth.toString();
      }

      if (intDate >= 0 && intDate <= 9) {
        strDate = "0" + intDate.toString();
      } else {
        strDate = intDate.toString();
      }

      if (intHour >= 0 && intHour <= 9) {
        strHour = "0" + intHour.toString();
      } else {
        strHour = intHour.toString();
      }
      //yyyyMMddHH
      var today = date.getFullYear() + strMonth + strDate + strHour;
      return today;
    };

    Vue.prototype.$isLogin = function() {
      //檢查登入
      var acc = this.$store.getters.acc;
      if (acc) {
        return true;
      } else {
        return false;
      }
    };

    Vue.prototype.$tsURL = function(url) {
      var getTimestamp = new Date().getTime();
      if (url.indexOf("?") > -1) {
        url = url + "&ts=" + getTimestamp;
      } else {
        url = url + "?ts=" + getTimestamp;
      }
      return url;
    };
  } //install
}; //default
