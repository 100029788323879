import Vue from "vue";
import Vuex from "vuex";

//將 store data 保存致 session/local storage
import createVuexAlong from "vuex-along";

Vue.use(Vuex);

// types
const types = {
  USER_INFO: "USER_INFO"
};

// state
const state = {
  userInfo: {}
};

const plugins = [
  createVuexAlong({
    local: {
      list: ["userInfo"] // 保存到 sessionStorage
    }
  })
];

// getters
const getters = {
  uid: state => {
    if (state.userInfo.uid) {
      return state.userInfo.uid;
    } else {
      return "";
    }
  },
  acc: state => {
    if (state.userInfo.acc) {
      return state.userInfo.acc;
    } else {
      return "";
    }
  },
  nick: state => {
    if (state.userInfo.nick) {
      return state.userInfo.nick;
    } else {
      return "";
    }
  },
  icon: state => {
    if (state.userInfo.icon) {
      return state.userInfo.icon;
    } else {
      return "0";
    }
  },
  pwd: state => {
    if (state.userInfo.pwd) {
      return state.userInfo.pwd;
    } else {
      return "";
    }
  },
  userInfo: state => state.userInfo
};

// mutations
const mutations = {
  [types.USER_INFO](state, userInfo) {
    if (userInfo) {
      state.userInfo = userInfo;
    } else {
      state.userInfo = null;
    }
  }
};

// actions
const actions = {
  setUserInfo: ({ commit }, userInfo) => {
    commit(types.USER_INFO, userInfo);
  }
};

export default new Vuex.Store({
  state,
  plugins,
  getters,
  mutations,
  actions
});
