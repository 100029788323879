<template>
  <div class="new-video-view">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="video-info"
        v-for="item in list"
        :key="item.videoID"
        @click="toVideoDetail(item)"
      >
        <!-- 影片圖檔 -->
        <div>
          <van-image class="video-img" :src="item.photo" lazy-load />
        </div>

        <!-- 影片說明 -->
        <div class="video-label">
          &ensp;{{ item.videoName }}．{{ item.actorName }}．{{ item.onDate }}
        </div>

        <!-- 分割線 -->
        <van-divider
          :style="{
            borderColor: 'black',
            margin: '0.3125rem 0.3125rem 0.0625rem 0.3125rem'
          }"
        ></van-divider>
      </div>
    </van-list>
  </div>
</template>

<script>
// NewVideoView : 今日最新
// call by HomeView.vue

import { List, Cell, CellGroup, Image, Divider } from "vant";
export default {
  name: "newVideoView",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0
    };
  },
  methods: {
    onLoad() {
      this.getData();
    },
    getData() {
      if (this.finished) {
        return;
      }
      this.loading = true;
      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(this.page.toString() + timeKey + secret);

      var url = base_url + "/NewVideoPlus";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            Page: that.page,
            PostKey: postKey
          }
        })
        .then(function (response) {
          var res = response.data.data;
          if (res.length > 0) {
            //把 data 加入 list
            res.forEach(item => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }
          // 加载状态结束
          that.loading = false;
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    },
    toVideoDetail(item) {
      this.$router.push({
        name: "videodetailx",
        query: {
          videoDetail: JSON.stringify(item),
        },
      });
    }, //toVideoDetail
    handlePopstate(event) {
      this.getData();
    },
  }, //methods
  mounted() {
    window.addEventListener('popstate', this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  },
};
</script>

<style>
.new-video-view {
  font-family: "Arial", "Helvetica", "sans-serif";
}

.new-video-view .video-img {
  width: 100%;
  padding: 0.3125rem 0 0.3125rem 0;
}

.new-video-view .video-label {
  /* padding: 0 0.3125rem 0 0.3125rem; */
  font-family: "Arial", "Helvetica", "sans-serif";
}

.new-video-view .video-hr {
  margin: 0.3125rem 0.3125rem 0.0625rem 0.3125rem;
}
</style>
