<template>
	<div class="ac-view">
		<!-- HEADER -->
		<HeaderView :title="title" :isLeft="true" />

		<div class="ac-info">
			<!--影片封面-->
			<van-image width="100%" :src="this.photo" lazy-load />
		</div>
	</div>
</template>

<script>
	import {
		Cell,
		CellGroup,
		List,
		Toast,
		Image,
		Tag,
		Divider
	} from "vant";
	import HeaderView from "../HeaderView/HeaderView.vue";
	

	export default {
		name: "videoDetailView",
		components: {
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[List.name]: List,
			[Toast.name]: Toast,
			[Image.name]: Image,
			[Tag.name]: Tag,
			[Divider.name]: Divider,
			HeaderView,
		},

		data() {
			return {
				title: "2025週年慶",
				photo: "https://m.s383.com/Img/Ac/a383_2025ny.jpg?q=",
			};
		}, //data

		computed: {
			
		}, //computed
		
		created() {}, //created
		mounted() {}, //mounted
		methods: {
			goToBuy() {
			this.$router.push('/buy'); // 導向 /buy 頁面
			}
		}, //methods
		activated() {} //activated
	};
</script>

<style>
	.video-image-view .video-photo {
		width: 100%;
	}

	.ac-view .ac-info {
		line-height: 1.5625rem;
	}

	.ac-view .ac-info {
		color: #222;
		border-color: 1px solid #ccc;
		background: -webkit-linear-gradient(#fff, #f1f1f1);
		background: -o-linear-gradient(#fff, #f1f1f1);
		background: -moz-linear-gradient(#fff, #f1f1f1);
		background: linear-gradient(#fff, #f1f1f1);
	}

	.ac-view .ac-info .video-desc {
		padding: 0 0.3125rem;
	}

	.ac-view .ac-info {
		font-size: 1rem;
	}

	.ac-view .ac-info .video-line {
		padding: 0.3125rem;
	}

	.ac-view .video-divider {
		margin: 0.3125rem;
		border-color: black;
		font-size: 1rem;
	}

	.ac-view {
		color: white;
	}
</style>
