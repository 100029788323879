<template>
  <div class="buy-view">
    <!-- HEADER -->
    <!-- <HeaderView title="購買中心" /> -->
    <HomeHeaderView title="383 影音城" />

    <!--CREDIT 入口-->
    <div v-if="IsBanner && StartH <=0 && EndH>0" class="credit-img">
      <van-image
        :src= "this.BannerSrc"
        width="100%"
        lazy-load
        @click="toYearEvent"
      />
      <!--<van-image
        src="https://m.s383.com/site/img/Banner/card_bonus_m383.gif"
        width="100%"
        lazy-load
        @click="toCredit"
      />-->
    </div>
    <div v-else class="credit-img">
      <van-image
        src="https://m.s383.com/site/img/Banner/card_bonus_m383.gif"
        width="100%"
        lazy-load
        @click="toCredit"
      />
    </div>

    <div class="point-info">
      <van-panel title="單筆消費5000元以上贈送全館無限看30天" icon="balance-o">
        <div class="point-info-table">
          <table>
            <tr>
              <th>方案</th>
              <th>金額</th>
              <th>點數</th>
              <th>省下金額</th>
            </tr>
            <tr v-for="item in plans" :key="item.type">
              <td>{{ item.type }}</td>
              <td>{{ item.price }} 台幣</td>
              <td>{{ item.point }} 點</td>
              <td>
                <span class="point-info-money">{{ item.salf }}</span> 元
              </td>
            </tr>
          </table>
        </div>
      </van-panel>
    </div>

    <div class="buy-info">
      <van-panel title="購買點數 - 台灣地區專用" icon="ecard-pay">
        <span class="buy-info-span">持信用卡、銀行轉帳、四大超商代收購點享有優惠點數</span>
        <div class="buy-info-table">
          <BuyItemView
            v-for="(item, index) in payments"
            :key="index"
            :item="item"
          />
        </div>
      </van-panel>
    </div>

    
    <div class="buy-info">
      <van-panel title="購買點數 - 全球地區專用" icon="credit-pay">
        <div class="buy-info-table">
          <BuyItemView
            v-for="(item, index) in foreign"
            :key="index"
            :item="item"
          />
        </div>
      </van-panel>
    </div>
    

    <div class="buy-info">
      <van-panel title=" 全館影片優惠價" icon="vip-card-o">
        <div class="buy-info-table">
          <div>單片 49 點 / 3 片特價 99 點</div>
          <div>消費一次 永久觀看</div>
        </div>
      </van-panel>
    </div>
  </div>
</template>

<script>
// BuyView : 購買
// call by App.vue
import {
  Cell,
  CellGroup,
  Icon,
  List,
  Row,
  Col,
  Image,
  Panel,
  Button,
} from "vant";
// import HeaderView from "../HeaderView/HeaderView.vue";
import HomeHeaderView from "../HeaderView/HomeHeaderView.vue";
import BuyItemView from "../BuyView/BuyItemView.vue";

export default {
  name: "buyView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Panel.name]: Panel,
    [Button.name]: Button,
    HomeHeaderView,
    BuyItemView,
  },
  data() {
    return {
      plans: [], //plans
      payments: [
        {
          icon: "https://www.a383.com/images/pay_vmcard.gif",
          title: "信用卡付費",
          type: "card",
        },
        {
          icon: "https://www.a383.com/images/pay_mobile.gif",
          title: "手機付費",
          type: "mobile",
        },
        {
          icon: "https://www.a383.com/images/pay_fami_life.gif",
          title: "超商代收",
          type: "fami",
        },
        {
          icon: "https://www.s383.com/images/pay_atm2.gif",
          title: "銀行轉帳",
          type: "atm",
        },
        {
          icon: "https://www.s383.com/images/pay_line2.gif",
          title: "LINE Pay",
          type: "linepay",
        },
        {
          icon: "https://www.a383.com/images/pay_hinet1.gif",
          title: "ADSL小額付費",
          type: "adsl",
        },
        {
          icon: "https://www.a383.com/images/pay_phone.gif",
          title: "家用電話",
          type: "phone",
        },
      ], //payments
      IsBanner:false,
      StartH:0,
      EndH:0,
      BannerSrc:"",
      foreign: [
        {
          icon: "https://www.a383.com/images/pay_vmcard.gif",
          title: "信用卡付費",
          type: "foreign",
        },
      ], //foreign
    }; //return
  }, //data
  computed: {},
  created() {
    console.log(this.arr);
    var base_url = process.env.VUE_APP_BASE_URL;
    var url = base_url + "/getEventM";
    this.$ajax.get(url).then((res) => {
      console.log(res);
      this.IsBanner = res.data.IsBanner;
      this.StartH = res.data.StartH;
      this.EndH = res.data.EndH;
      this.BannerSrc = "https://www.play104.com/Img/Banner/mobile_"+res.data.BannerName+".gif";
      if (this.StartH <= 0 && this.EndH > 0) {
        this.plans = [
          {
            type: "A+",
            price: "10000",
            point: "12600",
            salf: "2600",
          },
          {
            type: "A",
            price: "5000",
            point: "6250",
            salf: "1250",
          },
          {
            type: "B",
            price: "3000",
            point: "3600",
            salf: "600",
          },
          {
            type: "C",
            price: "2000",
            point: "2300",
            salf: "300",
          },
          {
            type: "D",
            price: "1000",
            point: "1100",
            salf: "100",
          },
          {
            type: "E",
            price: "500",
            point: "525",
            salf: "25",
          },
          {
            type: "F",
            price: "299",
            point: "300",
            salf: "",
          },
        ];
      } else {
        this.plans = [
          {
            type: "A+",
            price: "10000",
            point: "11100",
            salf: "1100",
          },
          {
            type: "A",
            price: "5000",
            point: "5500",
            salf: "500",
          },
          {
            type: "B",
            price: "3000",
            point: "3300",
            salf: "300",
          },
          {
            type: "C",
            price: "2000",
            point: "2150",
            salf: "150",
          },
          {
            type: "D",
            price: "1000",
            point: "1050",
            salf: "50",
          },
          {
            type: "E",
            price: "500",
            point: "500",
            salf: "",
          },
          {
            type: "F",
            price: "299",
            point: "300",
            salf: "",
          },
        ];
      }
    });
  },
  methods: {
    toCredit() {
      this.$router.push("/credit");
    },
    toYearEvent(){
      //this.$router.push("/acsummer");
    }
  },
};
</script>

<style scoped>
.buy-view {
  /* padding-top: 2.8125rem; */
  padding-bottom: 3.125rem;
  font-size: 1rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.point-info {
  padding: 0.625rem;
  /* border-radius: 10px; */
}

.point-info .van-cell {
  font-size: 1rem;
}

.point-info-table {
  padding: 0.9375rem;
  line-height: 1.125rem;
  font-size: 1rem;
}

.point-info-table table {
  border-collapse: collapse;
  width: 100%;
}

.point-info-table th,
td {
  text-align: right;
  padding: 0.25rem;
  width: 28%;
}
.point-info-table th:first-child,
td:first-child {
  width: 16%;
}

.point-info-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.buy-info {
  padding: 0 0.625rem 0.625rem 0.625rem;
}

.buy-info .van-cell {
  font-size: 1rem;
}

.buy-info-table {
  padding: 0.9375rem;
  line-height: 1.125rem;
}
.point-info-money {
  color: red;
}
.buy-info-span {
  font-size: 14px;
  padding: 0.9375rem;
}
</style>
