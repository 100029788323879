<template>
  <div class="video-preview-view">
    
    <!--     <van-grid :column-num="2" :border="false">
      <van-grid-item v-for="(item, index) in preview" :key="index">
        <van-image width="100%" :src="item" />
      </van-grid-item>
    </van-grid> -->

    <!--     <van-row>
      <van-col span="12" v-for="(item, index) in preview" :key="index">
        <van-image width="100%" :src="item" />
      </van-col>
    </van-row> -->

    <div class="preview-image-group">
      <div class="preview-image" v-for="(item, index) in preview" :key="index">
        <van-image width="100%" :src="item" />
      </div>
    </div>

  </div>
</template>

<script>
// VideoPreView : 影片預覽圖
// call by VideoDetailView.vue
// 一次載入
import { Grid, GridItem, Image, Cell, CellGroup, Row, Col } from "vant";

export default {
  name: "videoPreview",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col
  },
  props: ["preview"]
};
</script>

<style scoped>
/* .video-preview-view {
  overflow: hidden;
}

.video-preview-view .van-grid {
  background: gray;
}

.video-preview-view .van-grid-item {
  padding: 0rem;
}

.video-preview-view .van-grid-item__content {
  height: 100%;
  width: 100%;
  padding: 0rem;
} */

.video-preview-view .preview-image-group {
  border-top: 1px;
  border-left: 1px;
  overflow: hidden;
  padding: 0rem 0.3125rem 0rem 0rem;
}
.video-preview-view .preview-image {
  width: 50%;
  float: left;
  border-right: 1px;
  border-bottom: 1px;
  box-sizing: border-box;
  padding: 0rem 0rem 0rem 0.3125rem;
}
</style>
