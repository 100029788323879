<template>
  <div class="mine-view">
    <!-- HEADER -->
    <!-- <HeaderView title="我的" /> -->
    <HomeHeaderView title="A383 影音城" :isRight="false" />

    <div class="user-info">
      <br />

      <!--用戶圖片-->
      <van-row>
        <van-col span="8"></van-col>
        <van-col span="8" align="center">
          <van-image :src="userImg" />
        </van-col>
        <van-col span="8"></van-col>
      </van-row>

      <!-- 登入註冊按鈕 -->
      <div class="login-button" v-if="!isLogin">
        <van-row type="flex" justify="center">
          <van-col span="4" align="center" @click="toLogin()">登入</van-col>
          <van-col span="1" align="center">|</van-col>
          <van-col span="4" align="center" @click="toRegister()">註冊</van-col>
        </van-row>
      </div>
      <div class="login-button" v-else>
        <van-row type="flex" justify="center">
          <van-col span="9" align="center">{{ this.nick }}</van-col>
        </van-row>
        <van-row type="flex" justify="center">
          <van-col span="9" align="center">({{ accDesc(this.acc) }})</van-col>
        </van-row>
        <van-row type="flex" justify="center">
          <van-col span="16" align="center">剩餘點數：{{ this.point }}</van-col>
        </van-row>
        <van-row type="flex" justify="center">
          <van-col span="16" align="center">會員等級：{{ this.userLevel }}</van-col>
        </van-row>
        <br />
        <van-row type="flex" justify="center">
          <van-col span="9" align="center" @click="logout()">登出</van-col>
        </van-row>
      </div>

      <!--分隔線-->
      <br />
    </div>

    <!-- Nav Grid-->
    <van-grid :column-num="3" class="mine-grid" clickable>
      <van-grid-item icon="smile-o" text="會員中心" @click="toMemCenter" />
      <van-grid-item icon="service-o" text="客服中心" to="/csr" />
      <van-grid-item icon="video-o" text="我的影片" @click="toHistory" />
      <van-grid-item icon="plus" text="稍後觀看" @click="toLikeList" />
      <van-grid-item icon="exchange" text="紅利兌換" @click="toBonusRedeem" />
      <van-grid-item icon="shop-o" text="獎品商城" @click="toShop" />
    </van-grid>

    <!-- EXTERNAL Link -->
    <a ref="external_link" target="_blank" href="#"></a>
  </div>
</template>

<script>
// MineView : 我的
// call by App.vue

import { Grid, GridItem, Image, Row, Col, Divider, Dialog } from "vant";
// import HeaderView from "../HeaderView/HeaderView.vue";
import HomeHeaderView from "../HeaderView/HomeHeaderView.vue";

export default {
  name: "mineView",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Dialog.name]: Dialog,
    // HeaderView,
    HomeHeaderView,
  },
  data() {
    return {
      userInfo: {},
      acc: "",
      uid: "",
      nick: "",
      point: 0,
      icon: 0,
      isVIP: false,
    };
  },
  computed: {
    isLogin: function () {
      var acc = this.$store.getters.acc;
      if (acc) {
        return true;
      } else {
        return false;
      }
    },
    userImg: function () {
      var img = "";
      var acc = this.$store.getters.acc;
      if (acc) {
        img = "./img/user.jpg";
      } else {
        img = "./img/user-o-1.png";
      }
      if (this.isVIP) {
        img = "https://api.383family.com/img/vip.jpg";
      }
      if(this.icon.toString() == "3" )
      {
        img = "https://api.383family.com/img/vip.jpg";
      }
      if(this.icon.toString()=="4")
      {
        img = "https://api.383family.com/img/diamond.png";
      }
      return img;
    },
    userLevel: function () {
      var level = "";
      //var icon = this.$store.getters.icon;
      console.log("icon:", this.icon);
      var icon = this.icon.toString();
      switch (icon) {
        case "0":
          level = "一般會員";
          break;
        case "1":
          level = "普通會員";
          break;
        case "2":
          level = "金卡會員";
          break;
        case "3":
          level = "白金VIP";
          break;
        case "4":
          level = "鑽石VIP";
          break;
        default:
          level = "一般會員";
      }
      return level;
    },
  },
  created() {
    this.$emit("onActive", 4);
  },
  mounted() {
    //如果登入,則取得用戶資訊
    if (this.isLogin) {
      this.acc = this.$store.getters.acc;
      this.getMemInfo();
    }
  },
  methods: {
    toLogin() {
      //登入
      var env = process.env.VUE_APP_ENV;
      var url = "";
      if (env == "prod") {
        //正式環境登入
        url =
          "https://api.383family.com/Account/MemLogin?url=" +
          this.$route.fullPath;
        window.open(url,"_top");
        //window.location = url;
        
      } else {
        //測試用登入
        url = "/login1?url=" + this.$route.fullPath;
        this.$router.push(url);
      }
    },

    toRegister() {
      //註冊後回轉到 '/mine'
      let url =
        "https://api.383family.com/Account/MemReg?url=" + this.$route.fullPath;
      window.open(url,"_top");
      //window.location = url;
    },

    toBonusRedeem() {
      //轉到紅利兌換
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！",
        }).then(() => {
          // on close
        });
      } else {
        var url = "https://api.383family.com/Account/BonusRedeem";
        window.location = url;
      }
    }, //toBonusRedeem

    toShop() {
      //轉到紅利兌換
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！",
        }).then(() => {
          // on close
        });
      } else {
        //let url = `${window.location.origin}/#/shop`;
        let url = "https://m.play104.com/Store/ExchangeMall2";
        this.$refs.external_link.href = url;
        this.$refs.external_link.click();
      }
    }, //toShop

    logout() {
      this.userInfo = {};
      this.$store.dispatch("setUserInfo", this.userInfo);
      this.isVIP = false;
    },

    toMemCenter() {
      //轉到會員中心
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！",
        }).then(() => {
          // on close
        });
      } else {
        var acc = this.$store.getters.acc;
        var secret = process.env.VUE_APP_SECRET;
        var timeKey = this.$timeKey();
        var postKey = this.$md5(acc.toLowerCase() + timeKey + secret);
        var url =
          "https://api.383family.com/Account/MemCenter?" +
          "acc=" +
          acc +
          "&PostKey=" +
          postKey;

        window.location = url;
      }
    }, //toMemCenter

    toHistory() {
      //轉到我的影片
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！",
        }).then(() => {
          // on close
        });
      } else {
        this.$router.push("/history");
      }
    }, //toHistory

    toLikeList() {
      //轉到稍後觀看
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！",
        }).then(() => {
          // on close
        });
      } else {
        this.$router.push("/like");
      }
    }, //toLike

    getMemInfo() {
      //取得會員資料,暱稱與點數

      var acc = this.acc;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(acc.toLowerCase() + timeKey + secret);

      var url = base_url + "/MemInfo";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            Acc: acc,
            PostKey: postKey,
          },
        })
        .then(function (response) {
          var res = response.data;
          console.log("res:", res);
          that.nick = res.Nick;
          that.point = res.Point;
          that.isVIP = res.VIP;
          that.icon = res.icon;

          // console.log(res);
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
        });
    }, //getMemInfo

    accDesc(acc) {
      if (acc.length >= 20) {
        return acc.substr(0, 20) + "...";
      } else {
        return acc;
      }
    },
  }, //methods
}; //default
</script>

<style>
.mine-view {
  /* padding-top: 2.8125rem; */
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.mine-view .user-info {
  background: white;
  font-size: 1.125rem;
}

.mine-view .mine-grid .van-grid-item .van-grid-item__text {
  font-size: 1.125rem;
}

.mine-view .user-info .login-button {
  line-height: 1.5rem;
}
</style>
