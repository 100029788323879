<template>
<div class="visible-xs visible-md visible-sm" style="background-image: url('/img/index/bg.jpg');text-align:center;height: 100vh;">

<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12" style="margin-top: -30px;">
        <img src="/img/index/i3.png" alt="" />
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12" style="margin-top: -25px;">
        <img src="/img/index/i1.png" alt="" />
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12">
        <img src="/img/index/i2.png" alt="" />
    </div>
</div>

<div class="box box-solid">
    <div class="box-body">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <font size="2"> <b>A383</b>-包含成人,情色,A片,AV女優,美女,自拍,寫真,色情A片電影,成人網站內容，依&quot;電腦網際網路分級辦法&quot;為限制級，限定年滿18歲且具有完整行為能力之網友，未滿18歲謝絕進入且願接受A383成人影音內容及各項條款。為防範未滿18歲網友瀏覽網路上限制級內容的圖文資訊，建議您可進行<a href="http://i.win.org.tw/protection.php?Target=1" target="_blank"><font color="#FF0000">「iWIN網路內容防護機構」</font></a>過濾軟體的安裝與設定。</font>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-4">
                <a target="_blank" href="http://i.win.org.tw/protection.php?Target=1">
                    <img src="/img/index/i9.gif" alt="" />
                </a>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4">
                <a target="_blank" href="http://i.win.org.tw/protection.php?Target=1">
                    <img src="/img/index/i6.png" alt="" />
                </a>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4">
                <img src="/img/index/i5.png" alt="" />
            </div>
        </div>
    </div>
</div>

<div class="row">
    
<div class="col-xs-12 col-sm-12 col-md-12" style="margin-left: 30px;"> <a href="/#/home" target="_top"> 
<img src="/img/index/i7.gif" alt="" border="0" /> </a> <a href="https://tw.yahoo.com/"> 
<img width="76px" src="/img/index/i8.png" alt="" /> </a> </div>
</div>
<div style="margin:15px;"></div>
<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12">
        
<p><font size="2">免責聲明：<font color="#FF0000">本網站影片女優全部皆已滿18歲。</font></font> 
</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
</div>
</div>
</div>
</template>

<script>
// HomeView : 首頁
// call by App.vue

import { Swipe, SwipeItem, Cell, CellGroup, Tag, NavBar } from "vant";

export default {
  name: "homewarningView",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [NavBar.name]: NavBar,
  },
  setup() {
    // 進入網站
    const enterSite = () => {
      window.location.href = "/#/home"; // Vue Router 的首頁
    };

    // 離開網站
    const leaveSite = () => {
      window.location.href = "https://tw.yahoo.com/"; // 跳轉到其他安全網站
    };

    return { enterSite, leaveSite };
  },

  data() {
    return {
    };
  },
  computed: {},

  watch: {
  }, //watch

  created() {
  }, //created
  mounted() {
  },
  activated() {
    this.$emit("onBootom", false);
  },
  methods: {
  },
};
</script>

<style>
</style>
