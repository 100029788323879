<template>
  <div class="register-view">
    <!-- HEADER -->
    <HeaderView title="註冊" />
    <div>uid:{{ uid }}</div>
    <div>acc:{{ acc }}</div>
    <div>nick:{{ nick }}</div>
    <div>from:{{ from }}</div>
    <div>url:{{ url }}</div>
  </div>
</template>

<script>
// registerView : 註冊 url
// call by App.vue
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "registerView",
  components: {
    HeaderView
  },
  data() {
    return {
      uid: "",
      acc: "",
      nick: "",
      from: "",
      url: "",
      userInfo: {}
    };
  },
  computed: {},
  created() {
    this.uid = this.$route.query.uid;
    this.acc = this.$route.query.acc;
    this.nick = this.$route.query.nick;
    this.from = this.$route.query.from;
    this.url = this.$route.query.url;

    this.userInfo.uid = this.$route.query.uid;
    this.userInfo.acc = this.$route.query.acc;
    this.userInfo.nick = this.$route.query.nick;

    //註冊後直接登入
    //store userInfo to store
    this.$store.dispatch("setUserInfo", this.userInfo);

    //this.$tsURL 解決緩存問題
    this.url = this.$tsURL(this.url);
    this.$router.replace(this.url);
  }
};
</script>

<style>
.register-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}
</style>
