<template>
  <div class="free-video-view">
    <van-swipe :autoplay="3000" indicator-color="white" class="banner-view">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <img
          class="banner-img"
          v-lazy="item.photo"
          @click="toVideoDetail(item)"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
// FreeVideoView : 本日免費,輪播圖
// call by HomeView.vue

import { Swipe, SwipeItem } from "vant";
export default {
  name: "freeVideoView",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      list: [],
    };
  }, //data
  created() {
    var secret = process.env.VUE_APP_SECRET;
    var base_url = process.env.VUE_APP_BASE_URL;
    var timeKey = this.$timeKey();
    var postKey = this.$md5(timeKey + secret);

    // 獲取輪播圖數據
    //let url ="http://api.383family.com/A383/FreeVideo?PostKey=b99ab3dd73de4777c48e85fc48082464";
    var url = base_url + "/FreeVideoPlus";
    var that = this;
    this.$ajax
      .get(url, {
        params: {
          PostKey: postKey,
        },
      })
      .then(function (response) {
        // 獲取數據成功
        that.list = response.data.data;
        that.list = that.list.reverse();
      })
      .catch(function (err) {
        // 出錯
        console.log(err);
      });
  },
  methods: {
    
    /*     toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName
        }
      });
    } //toVideoDetail */

    toVideoDetail(item) {
      this.$router.push({
        name: "videodetailx",
        query: {
          videoDetail: JSON.stringify(item),
        },
      });
    }, //toVideoDetail

  }, //method
}; //default
</script>

<style>
.free-video-view .banner-view {
  height: 15.625rem;
  width: 100%;
}

.free-video-view .banner-view .banner-img {
  width: 100%;
  /* height: 100%; */
}
</style>
