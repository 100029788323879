<template>
  <div class="casino-info-view">
    <div class="casino-image-group">
      <van-image src="https://m.play104.com/Img/M/Title-GameInfo.jpg" width="100%" lazy-load />
      <div class="casino-image" v-for="(item, index) in list" :key="index">
        <van-image width="100%" :src="item" />
      </div>
    </div>
  </div>
</template>

<script>
// CasinoInfoView : 遊戲介紹
// call by CasinoView.vue
// 一次載入
import { Grid, GridItem, Image, Cell, CellGroup, Row, Col } from "vant";

export default {
  name: "videoPreview",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  props: ["preview"],
  data() {
    return {
      list: [
        "https://m.play104.com/Img/hg01.gif",
        "https://m.play104.com/Img/hg02.gif",
        "https://m.play104.com/Img/hg03.gif",
        "https://m.play104.com/Img/hg05.gif",
      ],
    };
  },
};
</script>

<style scoped>
.casino-info-view {
  background-color: #02a179;
  font-size:0;
}

.casino-info-view .casino-image-group {
  border-top: 0px;
  border-left: 0px;
  overflow: hidden;
  padding: 0rem 0rem 0rem 0rem;
}
.casino-info-view .casino-image {
  width: 50%;
  float: left;
  border-right: 0px;
  border-bottom: 0px;
  box-sizing: border-box;
  padding: 0rem 0rem 0rem 0rem;
}
</style>
