<template>
  <div class="nav-view">
    <!-- HEADER -->
    <!-- <HeaderView title="導航" /> -->
    <HomeHeaderView title="A383 影音城" />

    <!-- Nav Grid-->
    <van-grid :column-num="3" class="nav-grid" clickable>
      <van-grid-item icon="bullhorn-o" text="AV 情報" to="/article" />
      <van-grid-item icon="star-o" text="百大女優" to="/avtop" />
      <van-grid-item icon="search" text="索引" to="/searchindex" />
      <van-grid-item icon="point-gift-o" text="299 套餐" to="/month299" />
      <!-- <van-grid-item icon="gem-o" text="VIP 無限看" to="/month499" /> -->
      <van-grid-item icon="balance-o" text="賺點遊戲" @click="toHoGaming" />
      <van-grid-item icon="closed-eye" text="直播裸聊" @click="toChat" />
    </van-grid>

    <!-- <img src="/Img/Banner/a383_2020ny.jpg" width="100%" @click="toAC"> -->

    <!--Play104 入口-->
    <div class="play104-img">
      <van-image
        src="https://m.s383.com/Img/banner/s383_navi_banner.gif"
        width="100%"
        lazy-load
        @click="toPlay104"
      />
    </div>

    <!--CASINO 入口-->
    <div class="hogaming-img">
      <van-image
        src="https://m.a383.com/Img/Banner/hogaming_banner1.gif"
        width="100%"
        lazy-load
        @click="toHoGaming"
      />
    </div>

    <!-- EXTERNAL Link -->
    <a ref="external_link" target="_blank" href="#"></a>
    
  </div>
</template>

<script>
// NavView : 導航
// call by App.vue

import { Grid, GridItem, Dialog, Image } from "vant";
import HomeHeaderView from "../HeaderView/HomeHeaderView.vue";

export default {
  name: "navView",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Dialog.name]: Dialog,
    [Image.name]: Image,
    HomeHeaderView,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    toHoGaming() {
      //轉到會員中心
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！",
        }).then(() => {
          // on close
        });
      } else {
        var secret = process.env.VUE_APP_SECRET;
        var base_url = process.env.VUE_APP_BASE_URL;
        var timeKey = this.$timeKey();
        var postKey = this.$md5(
          timeKey + secret
        );
        
        var mid = this.$store.getters.uid;
        var url = base_url + "/ToHoGame?Acc="+mid+"&PostKey="+postKey;
        window.open(url);
      }
    },
    toPlay104() {
      this.$refs.external_link.href = "https://m.s383.com";
      this.$refs.external_link.click();
    },
    toCasino() {
      //登入檢查
      // if (!this.$isLogin()) {
      //   Dialog.alert({
      //     message: "請先登入會員！",
      //   }).then(() => {
      //     // on close
      //   });
      // } else {
      //   //this.$router.push("/casino");
      //   window.open("https://m.play104.com");
      // }
      window.open("https://m.play104.com");
    },
    toChat() {
      var url = "https://api.383family.com/fast/Chat/install.html";
      window.open(url);
    },
    toAC() {
      var url = "/acsummer#/nav";
      this.$router.push(url);
    },
  }, //methods
}; //default
</script>

<style>
.nav-view {
  /* padding-top: 2.8125rem; */
  padding-bottom: 3.125rem;
}

.nav-view .nav-grid .van-grid-item .van-grid-item__text {
  font-size: 1.125rem;
}

.play104-img {
  background: #ba53cc;
}
</style>
