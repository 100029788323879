<template>
  <div class="article-content-view">
    <!-- HEADER -->
    <HeaderView title="最新情報" :isLeft="true" />

    <!--主題-->
    <div class="article-info">
      <div class="article-title">
        <span>{{ articleInfo.Title }}</span>
      </div>
      <br />
      <van-row type="flex" justify="space-between">
        <van-col span="6">{{ articleInfo.PostDateString }}</van-col>
        <van-col span="6">{{ articleInfo.Author }}</van-col>
      </van-row>
    </div>

    <van-divider class="article-divider"></van-divider>

    <!--內容-->
    <div class="article-detail" v-html="articleInfo.Detail"></div>

    <!--LINE 連結-->
    <div class="article-line">
      <a :href="articleInfo.LineURL" target="_blank">
        <img src="img/line.png" alt width="140px" />
      </a>
    </div>

    <!--FB 連結-->
    <div class="article-fb-icon">
      <a href="https://www.a383.com/gofb.asp" target="_blank">
        <img src="https://www.a383.com/Img/FB-Search.png" width="140px" />
      </a>
    </div>

    <div class="article-fb-info">
      我們有臉書粉絲團囉，請幫我們按讚!追蹤最新的資訊!
    </div>

    <!--相關搜尋-->
    <van-divider class="article-divider">相關搜尋</van-divider>
    <ArticleTagView :tags="tags" @click="toSearch"></ArticleTagView>

    <!--系列影片-->
    <van-divider class="article-divider" v-if="articleInfo.VideoList"
      >系列影片</van-divider
    >
    <VideoListView
      :list="articleInfo.VideoList"
      @click="toVideoDetail"
    ></VideoListView>
  </div>
</template>

<script>
// ArticleContentView : 最新情報內容
// call by HomeView.vue, ArticleListView.vue

import { Cell, CellGroup, Toast, Row, Col, Divider } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";
import ArticleTagView from "../ArticleListView/ArticleTagView.vue";
import VideoListView from "../VideoDetailView/VideoListView.vue";

export default {
  name: "articleContentView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    HeaderView,
    ArticleTagView,
    VideoListView
  },
  data() {
    return {
      id: 0,
      articleInfo: {},
      tags: []
    };
  }, //data
  created() {
    this.id = this.$route.query.Id;
    //取得 ArticleContent
    this.getArticleContent();
  }, //created
  methods: {
    getArticleContent() {
      // 加載提示(加載數據)
      Toast.loading({
        className: "article-toast",
        icon: "/img/loader.gif",
        type: "loading",
        duration: 0,
        forbidClick: true
        //onClose: that.closeLoding // 關閉後的回調
      });

      var id = this.id;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(id.toString() + timeKey + secret);

      var url = base_url + "/ArticleContent";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            Id: id,
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;

          //使用 nextTick 避免在 created 時, 操作 DOM 元件錯誤
          that.$nextTick(function() {
            that.articleInfo = res[0];
            that.tags = that.articleInfo.Tag.split(",");
          });

          //清除輕提示
          Toast.clear();
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          //清除輕提示
          Toast.clear();
        });
    }, //getVideoDetail

    toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName
        }
      });
    }, //toVideoDetail

    toSearch(search) {
      this.$router.push({
        name: "searchvideo",
        query: {
          Search: search
        }
      });
    } //toSearch
  }, //methods

  activated() {
    if (this.id != this.$route.query.Id) {
      this.id = this.$route.query.Id;
      //取得最新情報內頁資訊
      this.getArticleContent();
    } else {
    }
  } //activated
}; //default
</script>

<style>
.article-content-view {
  padding-top: 2.875rem;
  padding-bottom: 3.125rem;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  font-size: 1rem;
  font-family: "Helvetica", "Arial", "sans-serif";
}

.article-content-view .article-info {
  padding: 0.625rem;
}

.article-content-view .article-info .article-title {
  font-size: 20px;
}

.article-content-view .article-detail {
  padding: 0.625rem 0.625rem 0 0.625rem;
  font-size: 0.9375rem;
  font-weight: lighter;
  line-height: 1.5625rem;
}

.article-content-view .article-detail img {
  width: 100%;
}

.article-content-view .article-divider {
  margin: 5px;
  border-color: black;
  font-size: 1rem;
}

.article-content-view .article-line {
  padding: 0.3125rem;
  width: 100px;
}

.article-content-view .article-fb-icon {
  padding: 0.3125rem 0.3125rem 0 0.3125rem;
  width: 100px;
  line-height: 1.5625rem;
}

.article-content-view .article-fb-info {
  padding: 0 0.3125rem 0.3125rem 0.3125rem;
  font-size: 0.9375rem;
  font-weight: lighter;
  line-height: 1.5625rem;
}

.article-toast {
  background-color: transparent;
}

.article-toast .van-toast__icon {
  font-size: 2.5rem;
}
</style>
