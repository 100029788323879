import '@babel/polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

import base from './base';
Vue.use(base);

import device from "vue-device-detector"
Vue.use(device)

// Axios
import Axios from 'axios'
// Vue原型屬性
Vue.prototype.$ajax = Axios

Vue.config.productionTip = false

// 懶加載
import {
  Lazyload
} from 'vant';
Vue.use(Lazyload);

// 狀態管理Vuex
import Vuex from 'vuex'
Vue.use(Vuex)

//md5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

//修改成繁中
import {
  Locale
} from "vant";
import zhTW from "vant/lib/locale/lang/zh-TW";
Locale.use("zh-TW", zhTW);

//浮動選單
import LyTab from 'ly-tab';
Vue.use(LyTab)

//修改 vant 內置樣式
import './css/resetvant.css'

new Vue({
  render: h => h(App),
  // 第五步: 添加路由管理器
  router,
  store // 狀態管理器
}).$mount('#app')
