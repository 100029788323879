<template>
  <div class="home-article-view">

    <!-- 最新情報列表 -->
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <ArticleItemView v-for="item in list" :key="item.Id" :item="item" @click="toArticleContent"></ArticleItemView>
    </van-list>
  </div>

</template>

<script>
// HomeArticleView : 最新情報,多筆
// call by HomeView.vue

import { List } from "vant";
import ArticleItemView from "../ArticleListView/ArticleItemView.vue";

export default {
  name: "homeArticleView",
  components: {
    [List.name]: List,
    ArticleItemView
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0
    };
  },
  computed: {},
  created() {
  }, //created
  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }
      this.loading = true;
      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(this.page.toString() + timeKey + secret);

      //let url ="http://api.383family.com/A383/ArticleList?Page=1&PostKey=b8f4b06737989d7fa2702726aff98f64"
      var url = base_url + "/ArticleList";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            Page: that.page,
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach(item => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }

          // 加載狀態結束
          that.loading = false;
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    toArticleContent(id) {
      this.$router.push({
        name: "articlecontent",
        query: {
          Id: id
        }
      });
    }, //toArticleContent

    initData() {
      return true;
    } // initData
  }, //methods

  activated() {
    //activated
  }

};
</script>

<style>
/* .home-article-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
} */
</style>
