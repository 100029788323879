<template>
  <div class="av-top-view">
    <!-- HEADER -->
    <HeaderView title="百大女優" :isLeft="true" />

    <!-- 百大女優列表 -->
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <AvTopItemView
        v-for="item in list"
        :key="item.order_id"
        :item="item"
        @click="toSearch"
      />
    </van-list>
    <GoTopView></GoTopView>
  </div>
</template>

<script>
// AvTopView : 百大女優
// call by NavView.vue

import { Cell, CellGroup, List, Row, Col, Image } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";
import AvTopItemView from "../AvTopView/AvTopItemView.vue";
import GoTopView from "../ScrollMenuView/GoTopView.vue";

export default {
  name: "AvTopView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    HeaderView,
    AvTopItemView,
    GoTopView
  },
  data() {
    return {
      title: "百大女優",
      navViewShow: false,

      list: [],
      loading: false,
      finished: false
    };
  },
  created() {
    // 發送標題
    this.$emit("onTitle", this.title);
    this.$emit("onNavShow", this.navViewShow);
  }, //created
  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }

      this.loading = true;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(timeKey + secret);

      //let url ="http://api.383family.com/A383/AvTOP100?PostKey=b99ab3dd73de4777c48e85fc48082464";
      var url = base_url + "/AvTOP100";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;

          //使用 nextTick 避免在 created 時, 操作 DOM 元件錯誤
          that.$nextTick(function() {
            that.list = res;
          });

          // 加載狀態結束
          that.loading = false;
          that.finished = true;
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    toSearch(search) {
      this.$router.push({
        name: "searchvideo",
        query: {
          Search: search
        }
      });
    } //toSearch
  } //methods
};
</script>

<style scoped>
.av-top-view {
  padding-top: 2.875rem;
  padding-bottom: 3.125rem;
  font-size: 16px;
  font-family: "Arial", "Helvetica", "sans-serif";
}

/* .av-top-view .van-list .van-cell {
  padding : 0rem;
  font-size: 1rem;
} */

/* .av-top-view .av-img {
  margin: 0.3125rem 0.3125rem 0 0.3125rem;
}

.av-top-view .av-desc {
  font-size: 1rem;
  vertical-align: middle;
} */
</style>
