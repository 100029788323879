<template>
  <div class="casino-view">
    <!-- HEADER -->
    <HeaderView title="真人美女發牌" :isLeft="isLeft" />
    <!-- <HomeHeaderView title="A383 影音城" /> -->

    <!--CASINO 入口-->
    <div class="casino-portal-img">
      <a rel="pre unsafe-url" :href="casino_url" target="_blank">
        <van-image src="https://m.play104.com/Img/hogaming_title_1.gif" width="100%" lazy-load />
      </a>
    </div>

    <!-- 遊戲介紹 -->
    <CasinoInfoView />

    <!-- 得獎名單 -->
    <CasinoWinnerView />
  </div>
</template>

<script>
// CasinoView : Casino
// call by CategoryAdView.vue

import { Cell, CellGroup, List, Image, Panel } from "vant";
// import HomeHeaderView from "../HeaderView/HomeHeaderView.vue";
import HeaderView from "../HeaderView/HeaderView.vue";
import CasinoInfoView from "../CasinoView/CasinoInfoView.vue";
import CasinoWinnerView from "../CasinoView/CasinoWinnerView.vue";

export default {
  name: "categoryView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Image.name]: Image,
    [Panel.name]: Panel,
    HeaderView,
    CasinoInfoView,
    CasinoWinnerView,
  },
  data() {
    return {
      title: "CASINO",
      navViewShow: false,
      isLeft: true,
      list: [],
      loading: false,
      finished: false,
      casino_url: "https://www.google.com/",
    };
  },
  computed: {},
  created() {
    // 發送標題
    this.$emit("onTitle", this.title);
    this.$emit("onNavShow", this.navViewShow);
    this.getCasinoURL();
  }, //created
  methods: {
    toCasino() {
      //https://chatapi.383family.com/S383/GoHoGaming?mid=1078735
      var url = "https://chatapi.383family.com/S383/GoHoGaming";
      var mid = this.$store.getters.uid;
      this.$ajax
        .get(url, {
          params: {
            mid,
          },
        })
        .then(function (response) {
          let url = response.data;
          console.log(url);
          //window.location = url;
          //window.open(url,"play104")
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
        });
    },
    getCasinoURL() {
      //https://chatapi.383family.com/S383/GoHoGaming?mid=1078735
      var url = "https://chatapi.383family.com/S383/GoHoGaming";
      var mid = this.$store.getters.uid;
      let that = this;
      this.$ajax
        .get(url, {
          params: {
            mid,
          },
        })
        .then(function (response) {
          let url = response.data;
          that.casino_url = url;
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
        });
    },
  }, //methods
  beforeRouteEnter(to, from, next) {
    console.log("beforeRouteEnter");
    next((vm) => {
      // access to component instance via `vm`
      console.log("isLogin:", vm.$isLogin());
      if (!vm.$isLogin()) {
        next("/");
      }
    });
  },
};
</script>

<style>
.casino-view {
  /* padding-top: 2.8125rem; */
  padding-bottom: 3.125rem;
  font-size: 1rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.casino-view .casino-portal-img{
  padding-top : 2.8125rem;
}

/* .casino-view .casino-list {
  background: whitesmoke;
  font-size: 1.125rem;
}

.casino-info {
  padding: 10px 10px 10px 10px;
}

.casino-info .van-cell {
  font-size: 1rem;
}

.casino-info .casino-info-img {
  padding: 0.9375rem;
} */
</style>
