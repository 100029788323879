import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}; */

// 第二步: 導入組件
import AcyearView from "./components/Ac/AcyearView.vue";
import AcsummerView from "./components/Ac/AcsummerView.vue";

import ArticleView from "./components/ArticleListView/ArticleView.vue";
import ArticleSearchView from "./components/ArticleListView/ArticleSearchView.vue";
import ArticleContentView from "./components/ArticleListView/ArticleContentView.vue";
import AvTopView from "./components/AvTopView/AvTopView.vue";

import BuyView from "./components/BuyView/BuyView.vue";

import CategoryView from "./components/CategoryView/CategoryView.vue";
import CategoryVideoView from "./components/CategoryView/CategoryVideoView.vue";
import CsrView from "./components/CsrView/CsrView.vue";

import HomeView from "./components/HomeView/HomeView.vue";
import HomeWarningView from "./components/HomeView/HomeWarningView.vue";
import HistoryView from "./components/HistoryView/HistoryView.vue";

import LikeListView from "./components/LikeListView/LikeListView.vue";
import LoginView from "./components/LoginView/LoginView.vue";
import LoginView1 from "./components/LoginView/LoginView1.vue";

import MineView from "./components/MineView/MineView.vue";
import Month299View from "./components/Month299View/Month299View.vue";
import Month499View from "./components/Month499View/Month499View.vue";

import NavView from "./components/NavView/NavView.vue";

import RegisterView from "./components/RegisterView/RegisterView.vue";

//import ScrollView from './components/ScrollMenuView/ScrollView.vue'
import SearchIndexView from "./components/SearchIndexView/SearchIndexView.vue";
import SearchVideoView from "./components/SearchVideoView/SearchVideoView.vue";

import VideoDetailView from "./components/VideoDetailView/VideoDetailView.vue";
import VideoDetailPreloadView from "./components/VideoDetailView/VideoDetailPreloadView.vue";

import CasinoView from "./components/CasinoView/CasinoView.vue";
import ShopView from "./components/ShopView/ShopView.vue";
import CreditView from "./components/CreditView/CreditView.vue";

// 第三步: 定義路由規則
const router = new Router({
  //mode: 'history',
  routes: [
    {
      path: "/",
      // component: HomeView
      redirect: "/homewarning"
    },
    {
      path: "/homewarning",
      name: "homewarning",
      component: HomeWarningView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/home",
      //redirect: '/searchindex',
      name: "home",
      component: HomeView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
			path: "/acyear",
			name: "acyear",
			component: AcyearView,
			meta: {
				keepAlive: true //需要被緩存的組件
			}
		},
    {
			path: "/acsummer",
			name: "acsummer",
			component: AcsummerView,
			meta: {
				keepAlive: true //需要被緩存的組件
			}
		},
    {
      path: "/category", //分類
      name: "category",
      component: CategoryView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/categoryvideo", //分類列表
      name: "categoryvideo",
      component: CategoryVideoView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/buy", //購買
      name: "buy",
      component: BuyView,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },
    {
      path: "/nav", //導航
      name: "nav",
      component: NavView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/mine", //我的
      name: "mine",
      component: MineView,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },
    {
      path: "/searchindex", //索引
      name: "searchindex",
      component: SearchIndexView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/videodetail",
      name: "videodetail",
      component: VideoDetailView,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },
    {
      path: "/videodetailx",
      name: "videodetailx",
      component: VideoDetailPreloadView,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },
    {
      path: "/searchvideo",
      name: "searchvideo",
      component: SearchVideoView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/avtop",
      name: "avtop",
      component: AvTopView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/articlecontent",
      name: "articlecontent",
      component: ArticleContentView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/article_search",
      name: "article_search",
      component: ArticleSearchView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/article",
      name: "article",
      component: ArticleView,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },

    {
      path: "/login1",
      name: "login1",
      component: LoginView1,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },

    {
      path: "/register",
      name: "register",
      component: RegisterView,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },

    {
      path: "/csr",
      name: "csr",
      component: CsrView,
      meta: {
        keepAlive: false //需要被緩存的組件
      }
    },

    {
      path: "/month299",
      name: "month299",
      component: Month299View,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },

    {
      path: "/month499",
      name: "month499",
      component: Month499View,
      meta: {
        keepAlive: true //需要被緩存的組件
      }
    },

    {
      path: "/history",
      name: "history",
      component: HistoryView,
      meta: {
        keepAlive: true , //需要被緩存的組件
        isBack: false //回上頁存
      }
    },
    {
      path: "/like",
      name: "like",
      component: LikeListView,
      meta: {
        keepAlive: true, //需要被緩存的組件
        isBack: false //回上頁存
      }
    },
    {
      path: "/casino",
      name: "casino",
      component: CasinoView,
      meta: {
        keepAlive: true, //需要被緩存的組件
        isBack: false //回上頁存
      }
    },
    {
      path: "/shop",
      name: "shop",
      component: ShopView,
      meta: {
        keepAlive: false, //需要被緩存的組件
        isBack: false //回上頁存
      }
    },
    {
      path: "/credit",
      name: "credit",
      component: CreditView,
      meta: {
        keepAlive: true, //需要被緩存的組件
        isBack: false //回上頁存
      }
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (to.fullPath == "/home") {
      //首頁置頂
      //return { x: 0, y: 0 };
    }
    if ((to.fullPath).indexOf('videodetail') != -1) {
      //內頁置頂
      return { x: 0, y: 0 };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  
  beforeRouteLeave(to, from, next) {
    if((to.fullPath).indexOf('videodetail') != -1) {
      from.meta.keepAlive = true;
    }else{
      from.meta.keepAlive = false;
    }
    next();
  }
});

export default router;
