<template>
  <div class="month-499-view">
    <!-- HEADER -->
    <HeaderView title="VIP 全館無限看" :isLeft="true" />

    <!-- 499 說明圖檔-->
    <div class="month-499-img-group">
      <van-image
        class="month-499-img"
        width="100%"
        src="http://m.a383.com/Img/Ac/499vip.jpg"
        lazy-load
      />
    </div>

    <!-- 用戶訂閱資訊 -->
    <MonthInfoView
      :monthInfoType="monthInfoType"
      :monthInfo="monthInfo"
      @toLogin="toLogin"
      @toRegister="toRegister"
    />

    <!-- 499 套餐影片 -->
    <div v-if="monthInfoType != 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="video-info"
          v-for="item in list"
          :key="item.product_id"
          @click="toVideoDetail(item)"
        >
          <!-- 影片圖檔 -->
          <div>
            <van-image
              class="video-img"
              :src="item.product_bigimg1"
              lazy-load
            />
          </div>

          <!-- 影片說明 -->
          <div class="video-label">
            &ensp;{{ item.product_name }}．{{ item.product_author }}．{{
              item.product_ttmin
            }}
            分鐘
          </div>

          <!-- 影片分割線 -->
          <van-divider
            :style="{
              borderColor: 'black',
              margin: '0.3125rem 0.3125rem 0.0625rem 0.3125rem'
            }"
          ></van-divider>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
// Month499View : 499 套餐
// call by NavView.vue,

import { List, Cell, CellGroup, Image, Divider, Panel, Toast } from "vant";

import HeaderView from "../HeaderView/HeaderView.vue";
import MonthInfoView from "../Month499View/MonthInfoView.vue";

export default {
  name: "month499View",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Panel.name]: Panel,
    [Toast.name]: Toast,
    HeaderView,
    MonthInfoView
  },
  data() {
    return {
      title: "VIP 無限看",
      navViewShow: false, //顯示分頁表頭

      list: [],
      loading: false,
      finished: false,
      page: 0,

      //0 未登入, 1 登入-未申裝 VIP, 2 登入-已申裝 VIP
      monthInfoType: 0,
      monthInfo: {}
    };
  },
  computed: {},

  created() {}, //created

  activated() {
    if (!this.$isLogin()) {
      //未登入
      this.monthInfoType = 0;
    } else {
      //取得用戶申裝列表
      this.getMonthInfo();
    }
  },

  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }

      this.loading = true;

      //要處理 uid 問題
      var uid = this.$store.getters.uid;

      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(
        uid.toString() + this.page.toString() + timeKey + secret
      );

      var url = base_url + "/Month499";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            uid: uid,
            page: that.page,
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach(item => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }
          // 加载状态结束
          that.loading = false;
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    toVideoDetail(item) {
      if (this.monthInfoType == 0) {
        return;
      }

      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.product_id,
          videoName: item.product_name
        }
      });
    }, //toVideoDetail

    initData() {
      //初始化變數
      return true;
    }, //initData

    getMonthInfo() {
      //取得用戶訂閱資料

      var acc = this.$store.getters.acc;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(acc.toLowerCase() + timeKey + secret);

      var url = base_url + "/getMonthInfo";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            acc: acc,
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;
          that.monthInfo = res;
          if (that.monthInfo.isVIP) {
            //已登入, 有 VIP
            that.monthInfoType = 2;
          } else {
            //已登入, 無 VIP
            that.monthInfoType = 1;
          }
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
        });
    },

    toLogin() {
      var env = process.env.VUE_APP_ENV;
      var url = "";
      if (env == "prod") {
        //正式環境登入
        url =
          "http://api.383family.com/Account/MemLogin?url=" +
          this.$route.fullPath;
        window.location = url;
      } else {
        //測試用登入
        url = "/login1?url=" + this.$route.fullPath;
        this.$router.push(url);
      }
    }, //toLogin

    toRegister() {
      //註冊後回轉
      let url =
        "https://api.383family.com/Account/MemReg?url=" + this.$route.fullPath;
      window.location = url;
    } //toRegister
  } //methods
};
</script>

<style>
.month-499-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.month-499-view .video-img {
  width: 22.5rem;
  height: 15rem;
  padding: 0.3125rem;
}

.month-499-view .video-label {
  /* padding: 0 0.3125rem 0 0.3125rem; */
  font-family: "Arial", "Helvetica", "sans-serif";
}

.month-499-view .video-hr {
  margin: 0.3125rem 0.3125rem 0.0625rem 0.3125rem;
}
</style>
