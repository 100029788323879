<template>
  <div class="video-header-view">
    <van-nav-bar
      :title="title"
      :left-arrow="isLeft"
      @click-left="back"
      fixed
    >
      <!--有登入才顯示右側按鈕-->
      <div v-if="isLogin" slot="right">
        <div v-if="typeof videoPlay.isPlay === 'boolean'">
          <div v-if="isLike">
            <van-icon
              v-if="isLike"
              name="success"
              class="video-like-icon"
              @click="$emit('delLike')"
            />
          </div>
          <div v-else>
            <van-icon name="plus" @click="$emit('addLike')" />
          </div>
        </div>
      </div>

    </van-nav-bar>
  </div>
</template>

<script>
// VideoHeaderView : 影片內頁表頭
// call by VideoDetail
import { Cell, CellGroup, NavBar, Icon, Tabbar, TabbarItem } from "vant";
export default {
  name: "videoHeaderView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    isLike: {
      type: Boolean,
      default: false,
    },
    videoPlay: {
      type : Object,
      default : null
    },
  },
  data() {
    return {
      //isLeft:true,
      //isLike: false
    };
  },
  computed: {
    isLogin() {
      return this.$isLogin();
    },
  },
  created() {},
  methods: {
    back(){
      return this.$router.go(-1);
    }
  },
};
</script>

<style>
.video-header-view {
  position: fixed;
  top: 0;
  height: 2.8125rem;
  z-index: 100;
  background: red;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.video-header-view .van-nav-bar {
  height: 2.8125rem;
  line-height: 2.8125rem;
  font-size: 1.5rem;
  font-weight: 900;
  color: black;
}

.video-header-view .van-nav-bar .van-nav-bar__title {
  font-size: 1.125rem;
  font-weight: 900;
  color: black;
}

.video-header-view .van-nav-bar .van-icon {
  font-size: 1.5rem;
  font-weight: 900;
  color: black;
  vertical-align: middle;
}

.video-header-view .van-nav-bar .video-like-icon {
  font-size: 1.5rem;
  font-weight: 900;
  color: red;
}
</style>
