<template>
  <div class="like-list-view">
    <!-- HEADER -->
    <HeaderView title="稍後觀看" :isLeft="true" />

    <!-- 影片類型列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="video-info"
        v-for="item in list"
        :key="item.videoID"
        @click="toVideoDetail(item)"
      >
        <div>
          <van-image class="video-img" :src="item.photo" lazy-load />
        </div>

        <div class="video-label">
          &ensp;{{ item.videoName }}．{{ item.actorName }}．{{
            item.onDate
          }}
        </div>

        <!-- 分割線 -->
        <van-divider
          :style="{
            borderColor: 'black',
            margin: '0.3125rem 0.3125rem 0.0625rem 0.3125rem',
          }"
        ></van-divider>
      </div>
    </van-list>
  </div>
</template>

<script>
// LikeListView : 稍後觀看
// call by MineView.vue

import { List, Cell, CellGroup, Image, Divider } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "likeListView",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider,
    HeaderView,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0,

      acc: "",
      isFirstEnter: true, //是否為首次進入
    };
  },
  computed: {},
  created() {}, //created
  mounted() {
    this.isFirstEnter = true;
  },
  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }

      this.loading = true;

      var acc = this.$store.getters.acc;

      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(
        acc.toLowerCase() + this.page.toString() + timeKey + secret
      );

      var url = base_url + "/LikeLISTPlus";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            acc: acc,
            Page: that.page,
            PostKey: postKey,
          },
        })
        .then(function (response) {
          let res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach((item) => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }

          // 加载状态结束
          that.loading = false;
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    /*     toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName
        }
      });
    }, //toVideoDetail */

    toVideoDetail(item) {
      this.$router.push({
        name: "videodetailx",
        query: {
          videoDetail: JSON.stringify(item),
        },
      });
    }, //toVideoDetail

    initData() {
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.page = 0;
      return true;
    },
  }, //methods

  beforeRouteEnter(to, from, next) {
    if (from.name == "videodetail") {
      // 这个name是下一级页面的路由name
      to.meta.isBack = true; // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
    }
    if (from.name == "videodetailx") {
      // 这个name是下一级页面的路由name
      to.meta.isBack = true; // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
    }
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.initData(); // 这里许要初始化dada()中的数据
      this.onLoad(); // 这里发起数据请求，（之前是放在created或者mounted中，现在只需要放在这里就好了，不需要再在created或者mounted中请求！！）
    }
    this.$route.meta.isBack = false; //请求完后进行初始化
    this.isFirstEnter = false; //请求完后进行初始化
  },
};
</script>

<style>
.like-list-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.like-list-view .video-img {
  width: 100%;
  padding: 0.3125rem 0 0.3125rem 0;
}
.like-list-view .video-label {
  /* padding: 0 0.3125rem 0 0.3125rem; */
  font-family: "Arial", "Helvetica", "sans-serif";
}
.like-list-view .video-hr {
  margin: 0.3125rem 0.3125rem 0.0625rem 0.3125rem;
}
</style>
