<template>
  <div class="buy-item-view">
    <van-cell>
      <van-row>
        <!--付費圖檔-->
        <van-col span="6" class="buy-icon-group">
          <van-image class="buy-icon" :src="item.icon" lazy-load />
          <span></span>
        </van-col>

        <!--付費按鈕-->
        <van-col span="18" class="buy-button-group">
          <van-button
            type="info"
            size="small"
            round
            class="buy-button"
            :text="item.title"
            @click="toPay(item.type)"
          />
          <span></span>
        </van-col>
      </van-row>
    </van-cell>
  </div>
</template>

<script>
// AvTopItemView : 女優資訊
// call by AvTopView.vue

import {
  Cell,
  CellGroup,
  List,
  Row,
  Col,
  Image,
  Icon,
  Button,
  Dialog
} from "vant";

export default {
  name: "BuyItemView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Dialog.name]: Dialog
  },
  data() {
    return {};
  },
  props: ["item"],
  created() {}, //created
  methods: {
    toPay(type) {
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！"
        }).then(() => {
          // on close
        });
        this.toLogin()
        return;
      }

      var memid = this.$store.getters.uid;
      var url = "";

      switch (type) {
        case "mobile":
          //url = "https://www.love104.com/bill/tele/paytele1_SP.asp?sel=7&memid=" + memid;
          url =
            "https://www.love104.com/bill/tele/paytele_SP.asp?sel=7&memid=" +
            memid;
          break;
        case "card":
          //url = "https://www.love104.com/ssl_new/paycard_esafe_SP.asp?sel=7&id=1&memid=" + memid;
          url =
            "https://api.383family.com/A383/CreditPay?memberid=" +
            memid;
          break;
        case "fami":
          url =
            "https://www.love104.com/bill/fami/store_SP.asp?sel=7&memid=" +
            memid;
          break;
        case "adsl":
          //url = "https://www.love104.com/bill/aaa/payaaa1.asp?sel=7&id=1&memid=" + memid;
          url =
            "https://www.love104.com/bill/aaa/payaaa.asp?sel=7&id=1&memid=" +
            memid;
          break;
        case "phone":
          //url = "https://www.love104.com/Pay/Tel/Member.asp?sel=7&memid=" + memid;
          url =
            "https://www.love104.com/Pay/Tel/Index.asp?sel=7&memid=" + memid;
          break;
        case "foreign":
          url =
            "https://www.love104.com/ssl_new/FunPay/funpay.asp?sel=7&memberid=" +
            memid;
          break;
        case "atm":
          url =
            "https://www.love104.com/pay/atm/index.asp?sel=7&id=1&memid=" +
            memid;
          break;
        case "linepay":
          url =
            "https://www.love104.com/bill/LinePay/index.asp?sel=7&id=1&memid=" +
            memid;
          break;
        default:
          url = "";
      }

      //window.open(' 新視窗的網址 ', '新視窗的名稱', config='height=高度,width=寬度');
      window.open(url);
    },

    toLogin() {
      //登入
      var env = process.env.VUE_APP_ENV;
      var url = "";
      if (env == "prod") {
        //正式環境登入
        url =
          "https://api.383family.com/Account/MemLogin?url=" +
          this.$route.fullPath;
        window.location = url;
      } else {
        //測試用登入
        url = "/login1?url=" + this.$route.fullPath;
        this.$router.push(url);
      }
    }
  } //methods
};
</script>

<style scoped>
.buy-item-view .buy-icon-group {
  height: 2.5rem;
  font-size: 1rem;
}

.buy-item-view .buy-icon-group .buy-icon {
  display: inline-block;
  vertical-align: middle;
}

.buy-item-view .buy-icon-group span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.buy-item-view .buy-button-group {
  height: 30px;
  font-size: 1rem;
}

.buy-item-view .buy-button-group .buy-button {
  /* display: inline-block; */
  /* vertical-align: middle; */
  font-size: 1rem;
  height: 2.5rem;
  width: 100%;
  background-color: #2d2d2d;
  border-color: #2d2d2d;
  background: -webkit-linear-gradient(#444, #2d2d2d);
  background: -o-linear-gradient(#444, #2d2d2d);
  background: -moz-linear-gradient(#444, #2d2d2d);
  background: linear-gradient(#444, #2d2d2d);
}
</style>
