<template>
  <div class="home-ad-view">
    <!-- 輪播廣告 -->
    <div class="home-ad-group">
      <div v-if="AdInfo">
        <van-image class="home-ad-img" :src="AdInfo.AdImg" @click="toAd" />
      </div>
    </div>
  </div>
</template>

<script>
// HomeAdView : 輪播廣告
// call by HomeView.vue

import { Image } from "vant";

export default {
  name: "homeAdView",
  components: {
    [Image.name]: Image
  },
  data() {
    return {
      Ad: [],
      AdInfo: {},
      pos: 0
    };
  },
  computed: {},
  created() {}, //created
  mounted() {
    this.getAd();
  },
  methods: {
    getAd() {
      //取得 AdInfo

      var base_url = process.env.VUE_APP_BASE_URL;
      var url = base_url + "/getAD";

      var that = this;
      this.$ajax
        .get(url)
        .then(function(response) {
          that.Ad = response.data.data;
          that.AdInfo = that.Ad[that.pos];
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
        });
    }, //onLoad

    toAd() {
      if(this.AdInfo.AdLink != ""){
        var url = this.AdInfo.AdLink;
        if(url.includes("http")){
          window.open(url);
        } else {
          this.$router.push(url);
        }
      }
    }, //toAd

    initData() {
      return true;
    } // initData
  }, //methods

  activated() {
    if (this.pos == this.Ad.length - 1) {
      this.pos = 0;
    } else {
      this.pos += 1;
    }
    this.AdInfo = this.Ad[this.pos];
  }
};
</script>

<style>
/* .home-ad-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
} */

.home-ad-view .home-ad-group .home-ad-img {
  width: 100%;
}
</style>
