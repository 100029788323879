<template>
  <div class="video-detail-view">
    <!-- HEADER -->
    <VideoHeaderView
      :title="videoDetail.videoName"
      :isLeft="true"
      :isLike="isLike"
      :videoPlay="videoPlay"
      @delLike="delLike"
      @addLike="addLike"
    />

    <div class="video-info" v-if="videoDetail.videoName">
      <!--影片封面-->
      <video-image-view :videoDetail="videoDetail" :videoPlay="videoPlay" />

      <!--點數資訊及按鈕-->
      <video-button-view
        v-if="videoPlay.isPlay != null"
        :videoPlay="videoPlay"
        @toRegister="toRegister"
        @toPay="toPay"
      />

      <!--影片參數-->
      <VideoDataView
        v-show="isShowData"
        :videoDetail="videoDetail"
        :videoPlay="videoPlay"
      />

      <!--影片資訊-->
      <div class="video-desc">
        <span class="videoName" @click="showData"
          >片名 : {{ videoDetail.videoName }}</span
        >
        <br />
        上架日期 : {{ videoDetail.onDate }}
        <br />
        類別 : {{ videoDetail.videoType }}
        <br />
        演員 : {{ videoDetail.actorName }}
        <br />
        影片長度 : {{ videoDetail.videoTime }} <span class="videoHD">{{ videoDetail.rate }}</span>
        <br />
        點播次數 : {{ videoDetail.playCnt }} <br />優惠價 : 單片 49 點 / 看 3
        片只要 99 點
        <br />
        <span>
          <font color="#FF0000">(消費一次永久觀看)</font>
        </span>
        <br />女優搜尋 :
        <van-tag
          round
          size="medium"
          plain
          class="search-tag"
          @click="toSearch()"
          >{{ searchTag }}</van-tag
        >
        <br />內容簡介 :
        <br />
        {{ videoDetail.desc }}
        <span class="sub-type">{{ subType }}</span>
      </div>

      <!--LINE 連結-->
      <div class="video-line">
        <a :href="videoDetail.Line" target="_blank">
          <img src="img/line.png" alt width="140px" />
        </a>
      </div>

      <!--預覽圖-->
      <VideoPreView :preview="videoDetail.preview" />
    </div>

    <!--系列影片-->
    <van-divider class="video-divider" v-if="videoDetail.series > 0"
      >系列影片</van-divider
    >
    <VideoListView :list="videoDetail.videoSeries" @click="toVideoDetail" />
  </div>
</template>

<script>
// VideoDetailView : 影片內頁
// call by CategoryVideoView

import { Cell, CellGroup, List, Toast, Image, Tag, Divider } from "vant";
import VideoHeaderView from "../HeaderView/VideoHeaderView.vue";
import VideoPreView from "../VideoDetailView/VideoPreView.vue";
import VideoListView from "../VideoDetailView/VideoListView.vue";
import VideoImageView from "../VideoDetailView/VideoImageView.vue";
import VideoButtonView from "../VideoDetailView/VideoButtonView.vue";
import VideoDataView from "../VideoDetailView/VideoDataView.vue";

export default {
  name: "videoDetailView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Toast.name]: Toast,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [Divider.name]: Divider,
    VideoHeaderView,
    VideoPreView,
    VideoListView,
    VideoImageView,
    VideoButtonView,
    VideoDataView,
  },

  data() {
    return {
      title: "影片內容",
      navViewShow: false,

      videoID: 0,
      videoName: "",

      videoDetail: {}, //videoDetail_info data
      videoPlay: {}, //videoDetail_isPlay data

      list: [],
      loading: false,
      finished: false,

      //顯示影片參數
      isShowData: false,

      //稍後觀看
      //isLike:false,
    };
  }, //data

  computed: {
    searchTag: function() {
      var str = this.videoDetail.actorName;
      try {
        var pos = str.indexOf(".");
        if (pos != -1) {
          //如果有 . , 則取前面字串
          str = str.substr(0, pos);
        }
      } catch (e) {
        str = "";
      }
      return str;
    },
    subType: function() {
      //中文字幕
      if (this.videoDetail.videoTypeID != 12) {
        return "(中文字幕)";
      } else {
        return "";
      }
    },
    isLike: function() {
      return this.videoDetail.isLike;
    },
  }, //computed

  created() {
    this.videoID = this.$route.query.videoID;
    this.videoName = this.$route.query.videoName;
  }, //created

  mounted() {
    //取得影片內頁資訊
    this.getVideoDetail();

    //取得影片撥放資訊
    this.getVideoPlay();
  }, //mounted

  methods: {
    getVideoDetail() {
      // 取得影片基本資料

      Toast.loading({
        className: "video-toast",
        icon: "/img/loader.gif",
        type: "loading",
        duration: 0,
        forbidClick: true,
        //onClose: that.closeLoding // 關閉後的回調
      });

      var vid = this.videoID;
      var uid = this.$store.getters.uid;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(vid.toString() + uid + timeKey + secret);

      var url = base_url + "/VideoDetail_info";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            vid: vid,
            uid: uid,
            PostKey: postKey,
          },
        })
        .then(function(response) {
          var res = response.data.data;

          //使用 nextTick 避免在 created 時, 操作 DOM 元件錯誤
          that.$nextTick(function() {
            that.videoDetail = res;
          });
          //清除輕提示
          Toast.clear();
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          //清除輕提示
          Toast.clear();
        });
    }, //getVideoDetail

    getVideoPlay() {
      //取得影片撥放資訊

      var vid = this.videoID;
      var uid = this.$store.getters.uid;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(vid.toString() + uid + timeKey + secret);

      var url = base_url + "/VideoDetail_isPlay";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            vid: vid,
            uid: uid,
            PostKey: postKey,
          },
        })
        .then(function(response) {
          var res = response.data.data;
          //使用 nextTick 避免在 created 時, 操作 DOM 元件錯誤
          that.$nextTick(function() {
            that.videoPlay = res;
          });
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
        });
    }, //getVideoPlay

    toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName,
        },
      });
    }, //toVideoDetail

    toSearch() {
      var search = this.searchTag;
      this.$router.push({
        name: "searchvideo",
        query: {
          Search: search,
        },
      });
    }, //toSearch

    payVideo(state) {
      //付費, 並取得新 videoURL
      //var payVideo = {};

      Toast.loading({
        className: "video-toast",
        icon: "/img/loader.gif",
        type: "loading",
        duration: 0,
        forbidClick: true,
        //onClose: that.closeLoding // 關閉後的回調
      });

      var acc = this.$store.getters.acc;
      var vid = this.videoID;
      var type = "pc";

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(
        acc.toLowerCase() + vid + type + state + timeKey + secret
      );

      var url = base_url + "/PayVideo";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            acc: acc,
            vid: vid,
            type: type,
            state: state,
            PostKey: postKey,
          },
        })
        .then(function(response) {
          var res = response.data.data;
          //使用 nextTick 避免在 created 時, 操作 DOM 元件錯誤
          that.$nextTick(function() {
            if (res != {}) {
              //扣點成功後必須更新 videoPlay
              that.videoPlay.state = res.state;
              that.videoPlay.stateDesc = res.stateDesc;
              that.videoPlay.isPlay = res.isPlay;
              that.videoPlay.MemberPoint = res.MemberPoint;
              that.videoPlay.cnt_99mov = res.cnt_99mov;
              that.videoPlay.cnt_6x = res.cut_6x;
              that.videoPlay.videoURL = res.videoURL;
            } else {
              //扣點失敗
            }
          });
          //清除輕提示
          Toast.clear();
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          //清除輕提示
          Toast.clear();
        });
    }, //playVideo

    toRegister() {
      //註冊後回轉
      let url =
        "https://api.383family.com/Account/MemReg?url=" + this.$route.fullPath;
      window.location = url;
    }, //toRegister

    toPay(state) {
      if (state == "buy") {
        this.$router.push("/buy");
      } else {
        this.payVideo(state);
      }
    }, //toPlay

    showData() {
      this.isShowData = !this.isShowData;
    }, //測試參數顯示

    addLike() {
      //加入稍後觀看

      Toast({
        className: "toast-text",
        message: "加入稍後觀看",
        icon: "success",
      });

      //call LikeEdit
      this.likeEdit("add");
      this.videoDetail.isLike = true;
    },

    delLike() {
      //移除稍後觀看
      Toast({
        className: "toast-text",
        message: "移除稍後觀看",
        icon: "cross",
      });

      //call LikeEdit
      this.likeEdit("del");
      this.videoDetail.isLike = false;
    },

    likeEdit(type) {
      var acc = this.$store.getters.acc;
      var vid = this.videoID;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(
        acc.toLowerCase() + vid.toString() + type + timeKey + secret
      );

      var url = base_url + "/LikeEDIT";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            acc: acc,
            vid: vid,
            type: type,
            PostKey: postKey,
          },
        })
        .then(function(response) {
          //var res = response.data
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
        });
    }, //likeEdit
  }, //methods

  activated() {}, //activated
};
</script>

<style>
.video-detail-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-size: 1rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.video-detail-view .video-info {
  line-height: 1.5625rem;
}

.video-detail-view .video-info .video-desc .search-tag {
  color: #222;
  border-color: 1px solid #ccc;
  background: -webkit-linear-gradient(#fff, #f1f1f1);
  background: -o-linear-gradient(#fff, #f1f1f1);
  background: -moz-linear-gradient(#fff, #f1f1f1);
  background: linear-gradient(#fff, #f1f1f1);
}

/* .video-detail-view .video-info .video-img {
  height: 15rem;
  padding: 0.3125rem 0 0 0.3125rem;
} */

.video-detail-view .video-info .video-desc {
  padding: 0 0.3125rem;
}

.video-detail-view .video-info .van-tag--medium {
  font-size: 1rem;
}

.video-detail-view .video-info .video-line {
  padding: 0.3125rem;
}

.video-detail-view .video-divider {
  margin: 0.3125rem;
  border-color: black;
  font-size: 1rem;
}

.video-toast {
  background-color: transparent;
}

.video-toast .van-toast__icon {
  font-size: 2.5rem;
}

.video-detail-view .toast-text {
  color: white;
}

.video-detail-view .sub-type {
  color: red;
}
</style>
