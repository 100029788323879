<template>
    <Transition name="fade">
    <div v-if="isShow" class="fixed right-4 bottom-4">
      <div class="toTop-arrow" @click="scrollTop">
      </div>
    </div>
  </Transition>
</template>

<script>

export default {
  name: 'ScrollTopBtn',
  
  data () {
    return {
      elTop: 0, // 滾動前,捲軸距離視窗頂部的距離
      isShow: false
    }
  },
  
  mounted () {
    window.addEventListener('scroll', this.scrolling)
    // 資料掛載完, window去監聽scroll事件
  },
  
  methods: {
      // 要滑到top為0的位置, 使用smooth的模式
    scrollTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
      
    scrolling () {
      // 捲軸距離視窗頂部的距離
      const scrolltoTop = window.pageYOffset
 
      // 捲軸滾動的距離
      const scrollLength = scrolltoTop - this.elTop

      // 更新: 滾動前,捲軸距離視窗頂部的距離
      this.elTop = scrolltoTop

      // 判斷想要什麼高度讓按鈕出現
      if (scrollLength < 0 && this.elTop < 200) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  }
}
</script>
<style>
/* 40px */
.toTop-arrow {
    display: flex;
	width: 2.5rem;
	height: 2.5rem;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 33%;
	opacity: 0.6;
	background: #000;
	cursor: pointer;
	position:fixed;
	right: 0.5rem;
	bottom: 3.5rem;
    align-items: center;
}
.toTop-arrow::before, .toTop-arrow::after {
	width: 18px;
	height: 5px;
	border-radius: 3px;
	background: #f90;
	position: absolute;
	content: "";
}
.toTop-arrow::before {
	transform: rotate(-45deg) translate(0, -50%);
	left: 0.5rem;
}
.toTop-arrow::after {
	transform: rotate(45deg) translate(0, -50%);
	right: 0.5rem;
}
.toTop-arrow:focus {outline: none;}
</style>