<template>
  <div class="search-video-view">
    <!-- HEADER -->
    <HeaderView :title="this.search" :isLeft="true" />

    <!-- 影片列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="video-info"
        v-for="item in list"
        :key="item.videoID"
        @click="toVideoDetail(item)"
      >
        <div>
          <van-image class="video-img" :src="item.photo" lazy-load />
        </div>

        <div class="video-label">
          &ensp;{{ item.videoName }}．{{ item.actorName }}．{{ item.onDate }}
        </div>

        <!-- <hr class="video-hr" /> -->
        <van-divider
          :style="{
            borderColor: 'black',
            margin: '0.3125rem 0.3125rem 0.0625rem 0.3125rem',
          }"
        ></van-divider>
      </div>
    </van-list>
  </div>
</template>

<script>
// SearchVideoView : 搜尋影片列表
// call by VideoDetailView.vue, SearchIndexView.vue

import { List, Cell, CellGroup, Image, Divider } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "searchVideoView",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider,
    HeaderView,
  },
  data() {
    return {
      title: "影片搜尋",
      navViewShow: false, //顯示分頁表頭

      list: [],
      loading: false,
      finished: false,
      page: 0,

      search: "",
    };
  },
  computed: {},
  created() {
    this.search = this.$route.query.Search;
  }, //created
  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }

      this.loading = true;

      var search = this.search;

      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(
        search.toLowerCase() + this.page.toString() + timeKey + secret
      );

      let url = base_url + "/SearchVideoPlus";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            Search: search,
            Page: that.page,
            PostKey: postKey,
          },
        })
        .then(function (response) {
          let res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach((item) => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }

          // 加载状态结束
          that.loading = false;
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    /*     toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName
        }
      });
    }, */

    toVideoDetail(item) {
      this.$router.push({
        name: "videodetailx",
        query: {
          videoDetail: JSON.stringify(item),
        },
      });
    }, //toVideoDetail

    initData() {
      return true;
    },
    handlePopstate(event) {
      this.onLoad();
    },
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  }, //methods

  activated() {
    if (this.search != this.$route.query.Search) {
      this.list = [];
      this.page = 0;
      this.search = this.$route.query.Search;
      this.onLoad();
    } else {
      //do nothing;
    }
  },
};
</script>

<style>
.search-video-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.search-video-view .video-img {
  width: 100%;
  padding: 0.3125rem 0 0.3125rem 0;
}
.search-video-view .video-label {
  /* padding: 0 0.3125rem 0 0.3125rem; */
  font-family: "Arial", "Helvetica", "sans-serif";
}
.search-video-view .video-hr {
  margin: 0.3125rem 0.3125rem 0.0625rem 0.3125rem;
}
</style>
