<template>
  <div class="category-view">
    <!-- HEADER -->
    <!-- <HeaderView title="分類" :isLeft="isLeft" /> -->
    <HomeHeaderView title="A383 影音城" />

    <!--CASINO Banner-->
    <CategoryAdView/>

    <!-- 類型清單 -->
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <van-cell
        v-for="item in list"
        :key="item.categoryID"
        :title="item.categoryName + videoCntDesc(item.VideoCnt)"
        class="category-list"
        @click="toCategoryVideo(item)"
        is-link
        border
      />
    </van-list>
  </div>
</template>

<script>
// CategoryView : 分類
// call by App.vue

import { Cell, CellGroup, List } from "vant";
import HomeHeaderView from "../HeaderView/HomeHeaderView.vue";
import CategoryAdView from "../CategoryView/CategoryAdView.vue";

export default {
  name: "categoryView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    HomeHeaderView,
    CategoryAdView,
  },
  data() {
    return {
      title: "分類",
      navViewShow: false,
      isLeft: false,
      list: [],
      loading: false,
      finished: false
    };
  },
  computed: {},
  created() {
    // 發送標題
    this.$emit("onTitle", this.title);
    this.$emit("onNavShow", this.navViewShow);
  }, //created
  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }

      this.loading = true;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(timeKey + secret);

      //let url ="http://api.383family.com/A383/Category?PostKey=b99ab3dd73de4777c48e85fc48082464";
      var url = base_url + "/Category";

      this.list = [
        {
          categoryID: 1,
          categoryName: "超人氣強檔片",
          VideoCnt: 0
        }
      ];

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach(item => {
              if (item.VideoCnt > 0) {
                that.list.push(item);
              }
            });
          }

          // 加載狀態結束
          that.loading = false;
          that.finished = true;
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad
    toCategoryVideo(item) {
      this.$router.push({
        name: "categoryvideo",
        query: {
          categoryID: item.categoryID,
          categoryName: item.categoryName
        }
      });
    }, //toCategoryVideo
    videoCntDesc(VideoCnt) {
      if (VideoCnt > 0) {
        return " (" + VideoCnt + "部影片) ";
      } else {
        return "";
      }
    } //videoCntDesc
  }, //methods
  beforeRouteLeave(to, from, next) {
    // 设置下一个路由的 meta
    //to.meta.keepAlive = false; // 让 A 不缓存，即刷新
    next();
  }
};
</script>

<style>
.category-view {
  /* padding-top: 2.8125rem; */
  padding-bottom: 3.125rem;
  font-size: 1rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

/* .category-view .van-list .van-cell {
  font-size: 1rem;
} */

.category-view .category-list {
  background: whitesmoke;
  font-size: 1.125rem;
}
</style>
