<template>
  <div class="shop-view">
  </div>
</template>

<script>
// ShopView : 購物商城
// call by App.vue
export default {
  name: "ShopView",
  components: {},
  data() {
    return {
      acc: null,
      pwd: null,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.acc = this.$store.getters.acc;
    this.pwd = this.$store.getters.pwd;
    console.log(this.acc, this.pwd);
    this.toPlayStore();
  },
  methods: {
    toPlayStore() {
      //https://api.383family.com/Account/LoginPlayStore?username=&password=
      let username = this.acc;
      let password = this.pwd;
      var url = "https://api.383family.com/Account/LoginPlayStore";
      this.$ajax
        .get(url, {
          params: {
            username,
            password,
          },
        })
        .then(function (response) {
          let url = response.request.responseURL;
          window.location.href = url;
          //console.log(res);
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>
