<template>
  <div class="search-index-view">
    <!-- HEADER -->
    <HeaderView title="索引" :isLeft="true" />

    <!-- SEARCH Box -->
    <van-search
      v-model="search"
      placeholder="請輸入搜索關鍵字"
      show-action
      shape="round"
      @search="onSearch"
      class="search-box"
    >
      <div slot="action" @click="onSearch">搜索</div>
    </van-search>

    <!-- Topic -->
    <van-cell-group v-for="(obj, index) in topicList" :key="index">
      <van-cell :title="obj.Topic" icon="location-o"></van-cell>
      <div class="topic-tag-group">
        <van-tag
          round
          v-for="(item, index) in obj.Items"
          :key="index"
          size="medium"
          plain
          class="topic-tag"
          @click="onTag(item.Item)"
          >{{ item.Item }}</van-tag
        >
      </div>
    </van-cell-group>
  </div>
</template>

<script>
// SearchIndexView : 索引
// call by HomeHeaderView.vue

import { Search, Cell, CellGroup, Tag } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "searchIndexView",
  components: {
    [Search.name]: Search,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    HeaderView
  },
  data() {
    return {
      search: "",
      searchDetailShow: false,
      searchDataList: null,
      topicList: []
    };
  },
  methods: {
    onSearch: function() {
      if (this.search) {
        var search = this.search;
        this.$router.push({
          name: "searchvideo",
          query: {
            Search: search
          }
        });
      }
    },
    onTag: function(search) {
      this.$router.push({
        name: "searchvideo",
        query: {
          Search: search
        }
      });
    }
  },
  created() {
    var secret = process.env.VUE_APP_SECRET;
    var base_url = process.env.VUE_APP_BASE_URL;
    var timeKey = this.$timeKey();
    var type = "all";
    var postKey = this.$md5(type + timeKey + secret);

    // 獲取搜尋索引數據
    let url = base_url + "/SearchIndex";
    var that = this;

    this.$ajax
      .get(url, {
        params: {
          type: type,
          PostKey: postKey
        }
      })
      .then(function(response) {
        // 獲取數據成功

        var res = response.data.data;

        //取得 topic
        var old_topicOrder = 0;
        var new_topicOrder = 0;
        var obj = {};
        if (res.length > 0) {
          res.forEach(row => {
            new_topicOrder = row.TopicOrder;
            if (old_topicOrder != new_topicOrder) {
              obj.Topic = row.Topic;
              obj.TopicOrder = new_topicOrder;
              obj.Items = [];
              that.topicList.push(obj);
              old_topicOrder = new_topicOrder;
              obj = {};
            }
          });
        }

        //取得 item
        var topicOrder = 0;
        var item = "";
        var item_obj = {};
        if (res.length > 0) {
          res.forEach(row => {
            topicOrder = row.TopicOrder;
            item = row.Item;
            that.topicList.forEach(obj => {
              if (obj.TopicOrder == topicOrder) {
                item_obj.Item = item;
                obj.Items.push(item_obj);
                item_obj = {};
              }
            });
          });
        }
      })
      .catch(function(err) {
        // 出錯
        console.log(err);
      });
  } //created
};
</script>

<style>
.search-index-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-size: 1rem;
}

.search-index-view .van-search__action {
  font-size: 1rem;
}

.search-index-view .topic-tag-group {
  padding: 0 0.625rem 0 0.625rem;
}

.search-index-view .topic-tag-group .topic-tag {
  color: #222;
  border-color: 1px solid #ccc;
  background: -webkit-linear-gradient(#fff, #f1f1f1);
  background: -o-linear-gradient(#fff, #f1f1f1);
  background: -moz-linear-gradient(#fff, #f1f1f1);
  background: linear-gradient(#fff, #f1f1f1);
}

.search-index-view .van-tag--default {
  margin: 0.3125rem;
}

.search-index-view .van-tag--medium {
  font-size: 1rem;
}

.search-index-view .van-cell {
  font-size: 1rem;
}
</style>
