<template>
  <div class="history-view">
    <!-- HEADER -->
    <HeaderView title="我的影片" :isLeft="true" />

    <!-- 年份橫向 -->
    <HistoryMenuView @change="toYear" :menuId="yearId" :CId="0" />
    <!-- 橫向選單 -->
    <HistoryMenuView @change="toScroll" :menuId="menuId" :CId="1" />

    <!--分類影片-->
    <div class="history-video">
      <history-video-view :category="menuVal" :year="yearVal" :key="indexKey" />
      <!-- <history-video-view :category="menuVal" :key="videoKey" /> -->
    </div>
    <GoTopView></GoTopView>
  </div>
</template>

<script>
// HistoryView : 我的影片
// call by NavView.vue

import { List, Cell, CellGroup, Image, Divider } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";
import HistoryMenuView from "../HistoryView/HistoryMenuView.vue";
import HistoryVideoView from "../HistoryView/HistoryVideoView.vue";
import GoTopView from "../ScrollMenuView/GoTopView.vue";

export default {
  name: "histroyView",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider,
    HeaderView,
    HistoryMenuView,
    HistoryVideoView,
    GoTopView
  },
  data() {
    return {
      isFirstEnter: true, //是否為首次進入
      menu: {},
      menuId: 0,
      menuVal: 0, //CategoryID
      menuName: "全部", //ShortName
      indexKey: 0,
      yearId: 0,
      yearVal: 0,
      yearName: "全部"
    };
  },
  computed: {},
  created() {}, //created,
  methods: {
    toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName
        }
      });
    }, //toVideoDetail

    initData() {
      return true;
    },

    toScroll(selected) {
      this.menuId = selected.MenuId;
      this.menuVal = selected.MenuVal;
      this.indexKey = this.getIndexKey();
    }, //toScroll
    toYear(selected){
      this.yearId = selected.MenuId;
      this.yearVal = selected.MenuVal;
      this.indexKey = this.getIndexKey();
    },
    getIndexKey() {
      //return Math.floor(Math.random()*(500-100+1))+100
      return new Date().getTime();
    }
  }, //methods

  beforeRouteEnter(to, from, next) {
    if (from.name == "videodetail") {
      // 这个name是下一级页面的路由name
      to.meta.isBack = true; // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
    }
    if (from.name == "videodetailx") {
      // 这个name是下一级页面的路由name
      to.meta.isBack = true; // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
    }
    if (from.name == "mine") {
      // 这个name是下一级页面的路由name
      to.meta.isBack = false; // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
    }
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.menuId = 0;
      this.menuVal = 0;
      this.yearId = 0;
      this.yearVal = 0;
      this.indexKey = this.getIndexKey();
    }
    this.$route.meta.isBack = false; //请求完后进行初始化
    this.isFirstEnter = false; //请求完后进行初始化
  }
};
</script>

<style>
.history-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}
</style>
