<template>
  <div class="home-header-view">
    <van-row>
      <van-col span="17" offset="1">
        <div class="header-logo-group">
          <van-image
            class="header-logo"
            width="140"
            src="/img/logo-1.png"
            fit="cover"
            @click="toHome"
          />
          <span></span>
        </div>
      </van-col>

      <van-col span="3">
        <div class="header-search-group" v-show="isRight">
          <van-image
            class="header-search"
            width="70%"
            round
            fit="contain"
            src="/img/search-icon.png"
            @click="toSearch"
          />
          <span></span>
        </div>
      </van-col>

      <van-col span="3">
        <div class="header-login-group" v-show="isRight">
          <van-image
            class="header-login"
            width="70%"
            round
            fit="contain"
            :src="loginIcon"
            @click="toLogin"
          />
          <span></span>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
// HeaderView : 首頁表頭
// call by HomeView.vue
import { Row, Col, Icon, Button, Image } from "vant";
export default {
  name: "homeHeaderView",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Image.name]: Image,
  },
  props: {
    isRight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    loginIcon() {
      if (this.$isLogin()) {
        return "/img/user.jpg";
      } else {
        return "/img/user-o.png";
      }
    },
  },
  created() {},
  methods: {
    toSearch() {
      this.$router.push("/searchIndex");
    },
    toHome() {
      this.$router.push("/home");
      window.location.reload();
    },

    toLogin() {
      //如果已登入,轉去會員中心
      if (this.$isLogin()) {
        //alert('login')
        window.localStorage.setItem("itemIndex", 4);
        this.$router.push("/mine");
        return;
      }

      //未登入也轉去會員中心
      window.localStorage.setItem("itemIndex", 4);
      this.$router.push("/mine");
      return;

      var env = process.env.VUE_APP_ENV;
      var url = "";
      if (env == "prod") {
        //正式環境登入
        url =
          "https://api.383family.com/Account/MemLogin?url=" +
          this.$route.fullPath;
        window.location = url;
      } else {
        //測試用登入
        url = "/login1?url=" + this.$route.fullPath;
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped>
.home-header-view {
  /* position: fixed;  */
  top: 0;
  height: 2.8125rem;
  width: 100%;
  z-index: 100;
  background: white;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.home-header-view .header-logo-group {
  height: 2.8125rem;
}

.header-logo-group .header-logo {
  display: inline-block;
  vertical-align: middle;
}

.header-logo-group span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.home-header-view .header-search-group {
  height: 2.8125rem;
  text-align: center;
}

.header-search-group .header-search {
  height: 70%;
  display: inline-block;
  vertical-align: middle;
}

.header-search-group span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.home-header-view .header-login-group {
  height: 2.8125rem;
}

.header-login-group .header-login {
  height: 70%;
  display: inline-block;
  vertical-align: middle;
}

.header-login-group span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
</style>
