<template>
  <div class="article-search-view">
    <!-- HEADER -->
    <HeaderView :title="this.search" :isLeft="true" />

    <!-- 最新情報列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <ArticleItemView
        v-for="item in list"
        :key="item.Id"
        :item="item"
        @click="toArticleContent"
      ></ArticleItemView>
    </van-list>
  </div>
</template>

<script>
// ArticleSearchView : 最新情報搜尋結果,多筆
// call by NavView.vue

import { List } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";
import ArticleItemView from "../ArticleListView/ArticleItemView.vue";

export default {
  name: "articleSearchView",
  components: {
    [List.name]: List,
    HeaderView,
    ArticleItemView
  },
  data() {
    return {
      title: "最新情報",
      navViewShow: false, //顯示分頁表頭

      list: [],
      loading: false,
      finished: false,
      page: 0,

      search: "" //搜尋關鍵字
    };
  },
  computed: {},
  created() {
    // 發送標題
    this.$emit("onTitle", this.title);
    this.$emit("onNavShow", this.navViewShow);

    // 取得搜尋
    this.search = this.$route.query.Search;
  }, //created
  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }
      this.loading = true;

      var search = this.search;

      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(
        this.page.toString() + search.toLowerCase() + timeKey + secret
      );

      var url = base_url + "/ArticleListPlus";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            Page: that.page,
            Search: search,
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach(item => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }

          // 加載狀態結束
          that.loading = false;
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    toArticleContent(id) {
      this.$router.push({
        name: "articlecontent",
        query: {
          Id: id
        }
      });
    }, //toArticleContent

    initData() {
      return true;
    } // initData
  }, //methods

  activated() {
    //activated
  }
};
</script>

<style>
.article-search-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}
</style>
