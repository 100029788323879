<template>
  <div class="article-item-view">
    <van-row @click="$emit('click',item.Id)" align="center">
      <van-col span="9">
        <van-image class="article-img" :src="item.Photo" lazy-load />
      </van-col>

      <van-col span="13" class="article-desc">

        <div class="article-desc-data">
          {{item.Title}}
          <br />
          發佈 : {{item.PostDate}}
          <br />
          發文 : {{item.Author}}
          <br />
          <div class="desc">{{item.Desc}}</div>
        </div>
        <span></span>
      </van-col>

      <van-col span="2" class="article-arrow">

        <van-icon name="arrow" class="article-arrow-data" />
        <span></span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
// articleItemView : 最新情報資訊
// call by articleView.vue

import { Cell, CellGroup, List, Row, Col, Image, Icon } from "vant";

export default {
  name: "articleItemView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon
  },
  data() {
    return {};
  },
  props: ["item"],
  created() {}, //created
  methods: {} //methods
};
</script>

<style>
/* .article-item-view {

} */

.article-item-view .article-img {
  height: 7.8125rem;
  width: 7.8125rem;
  margin: 0.3125rem 0.3125rem 0 0.3125rem;
}

.article-item-view .article-desc {
  height: 8.2rem;
  font-size: .9375rem;
  line-height: 1.25rem;
}

.article-item-view .article-desc .desc {
  font-size: .8125rem;
  font-weight: lighter;
}

.article-item-view .article-desc .article-desc-data {
  display: inline-block;
  vertical-align: middle;
}
.article-item-view .article-desc span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.article-item-view .article-arrow {
  height: 8.2rem;
  float: left;
}

.article-item-view .article-arrow .article-arrow-data {
  display: inline-block;
  vertical-align: middle;
}
.article-item-view .article-arrow span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
</style>
