<template>
  <div class="home-view">
    <div v-show="chkAPP" class="androidA2HS">
      <div>
        <span @click="closeMod">x</span>
        <img src="/img/icons/icon-96x96.png" />
        <div>
          <span>A383行動版</span>
          <span>使用行動版捷徑快速訪問</span>
        </div>
      </div>
      <button @click="showInstall">立即取得</button>
    </div>
    <!-- HEADER -->
    <HomeHeaderView title="A383 影音城" />

    <!-- 浮動選單 -->
    <ScrollMenuView @click="toScroll" :menuId="menuId" />

    <div class="home-new" v-if="menuId == 0">
      <!--輪播廣告-->
      <HomeAdView />

      <!-- 本日免費看 -->
      <van-cell title="本日免費看" icon="point-gift-o" />
      <FreeVideoView />

      <!--最新情報-->
      <van-cell title="最新情報" icon="comment-o" />
      <ArticleListView />

      <!--今日最新-->
      <van-cell title="今日最新" icon="good-job-o" />
      <NewVideoView />
    </div>

    <!--分類影片-->
    <div class="home-category" v-if="isCategory()">
      <home-category-view :params="menuVal" :key="menuId" />
    </div>

    <!--最新情報-->
    <div class="home-article" v-if="menuVal == 'NewsList'">
      <home-article-view />
    </div>
  </div>
</template>

<script>
// HomeView : 首頁
// call by App.vue

import { Swipe, SwipeItem, Cell, CellGroup, Tag, NavBar } from "vant";

import HomeHeaderView from "../HeaderView/HomeHeaderView.vue";
import ScrollMenuView from "../ScrollMenuView/ScrollMenuView.vue";
import FreeVideoView from "../FreeVideoView/FreeVideoView.vue";
import ArticleListView from "../ArticleListView/ArticleListView.vue";
import NewVideoView from "../NewVideoView/NewVideoView.vue";
import HomeCategoryView from "../HomeView/HomeCategoryView.vue";
import HomeArticleView from "../HomeView/HomeArticleView.vue";
import HomeAdView from "../HomeView/HomeAdView.vue";

export default {
  name: "homeView",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [NavBar.name]: NavBar,

    ScrollMenuView,
    NewVideoView,
    ArticleListView,
    HomeHeaderView,
    FreeVideoView,
    HomeCategoryView,
    HomeArticleView,
    HomeAdView,
  },

  props: ["toHome"],

  data() {
    return {
      menu: {},
      menuId: 0,
      menuVal: "",
      menuName: "",
      chkAPP: false,
    };
  },
  computed: {},

  watch: {
    toHome() {
      this.menuId = 0;
    },
  }, //watch

  created() {
    this.menuId = 0;
    this.menuVal = "";
    this.chkAPP = this.chkIOSAndSafari();
  }, //created
  mounted() {
  },
  activated() {
    this.$emit("onActive", 0);
    this.$emit("onBootom", true);
  },
  methods: {
    toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName,
        },
      });
    }, //toVideoDetail
    toScroll(selected) {
      this.menuId = selected.MenuId;
      this.menuVal = selected.MenuVal;
    }, //toScroll

    isCategory() {
      var str = this.menuVal;
      return str.indexOf("categoryID") != -1;
    },
    fnBrowserDetect: function () {
      let userAgent = navigator.userAgent;
      let browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detection";
      }
      return browserName;
    },
    chkIOSAndSafari: function () {
      var browserName = this.fnBrowserDetect();
      var OS = navigator.platform;
      if (browserName == "safari" && OS == "iPhone" && this.isShowInstallApp()) {
        return true;
      } else {
        return false;
      }
      //return true;
    },
    isShowInstallApp: function () {
      var times = localStorage.getItem("installApp");
      if (times) {
        times = parseInt(times);
        if (times >= 5) {
          return false;
        }
        else {
          this.setInstallApp(times);
          return true;
        }
      }
      else {
        this.setInstallApp(0);
        return true;
      }
    },
    setInstallApp: function (times) {
      localStorage.setItem("installApp", ++times);
    },
    showInstall: function () {
      document.querySelector(".androidA2HS").style.display = "none";
      var span = document.getElementsByClassName("close")[0];
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
      span.onclick = function () {
        modal.style.display = "none";
      }
    },
    closeMod: function () {
      document.querySelector(".androidA2HS").style.display = "none";
    }
  },
};
</script>

<style>
.home-view {
  /* padding-top: 2.8125rem; */
  padding-bottom: 3.125rem;
  /* overflow: auto;
  overflow-x: hidden; */
}

/* .home-view > .home-new {
  overflow-x: hidden;
}  */

.home-view>.home-new>.van-cell {
  font-size: 1rem;
  padding: 0.125rem 0.3125rem 0.125rem 0.3125rem;
}

.androidA2HS {
  background: #3b3b3b;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
  align-items: center;
  height: 62px;
}

.androidA2HS>div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.androidA2HS>div>span {
  color: #a19f9f;
  opacity: 1;
  text-shadow: none;
  font-size: 28px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 12px;
}

.androidA2HS>div>img {
  width: 60px;
  border-radius: 14px;
  margin-left: 5px;
}

.androidA2HS>div>div {
  display: flex;
  flex-direction: column;
  text-shadow: none;
  color: white;
  padding-left: 10px;
}

.androidA2HS>div>div>span:nth-child(1) {
  font-weight: bold;
  line-height: 1.6rem;
}

.androidA2HS>div>div>span:nth-child(2) {
  font-size: 14px;
}

.androidA2HS>button {
  border-radius: 16px;
  background-color: royalblue;
  border-width: 0px;
  color: white;
  font-size: 1rem;
  padding: 6px 15px;
  margin-right: 10px;
}
</style>
