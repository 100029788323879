<template>
  <div class="history-menu-view">
    <ly-tab
      v-model="selectedId"
      :items="menu"
      :options="options"
      @change="onChange"
    ></ly-tab>
  </div>
</template>

<script>
// HistoryMenuView : 我的影片橫向滾動選單
// call by HistoryView.vue

import { Tab, Tabs } from "vant";

export default {
  name: "historyMenuView",
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  props: {
    menuId: {
      type: Number,
      default: 0
    },
    CId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: 0,

      menu: [],
      selectedId: 0,
      options: {
        activeColor: "#FF44AA",
        labelKey: "MenuName"
        // 可在這裡指定labelKey為你數據裡文字對應的字段
      }
    };
  },

  watch: {
    menuId() {
      this.selectedId = this.menuId;
    }
  }, //watch

  created() {
    this.selectedId = this.menuId;
    
    var secret = process.env.VUE_APP_SECRET;
    var base_url = process.env.VUE_APP_BASE_URL;
    var timeKey = this.$timeKey();
    var postKey = this.$md5(timeKey + secret);

    
    // 獲取 ScrollMenu Data
    var url = base_url + "/HistoryCategory";

    var that = this;
    if(this.CId == 0){
      that.menu = [{ MenuName: "年份", MenuVal: 0 }];
      var data = {};
      var currentTime = new Date();
      var NowY = currentTime.getFullYear();
      for(var i = NowY;i >= 2012;i--) {
        data = {
          MenuName: i.toString(),
          MenuVal: i
        };
        that.menu.push(data);
      }
    }else{
    this.$ajax
      .get(url, {
        params: {
          PostKey: postKey
        }
      })
      .then(function(response) {
        // 獲取數據成功
        var res = response.data.data;     

        if (res.length > 0) {

          //第一組為全部
          that.menu = [{ MenuName: "全部", MenuVal: 0 }];

          //把 data 加入 list
          var data = {};
          res.forEach(item => {
            data = {
              MenuName: item.ShortName,
              MenuVal: item.categoryID
            };
            that.menu.push(data);
          });
        } else {
          that.menu = [];
        }
      
        
      })
      .catch(function(err) {
        // 出錯
        console.log(err);
      })}
  }, //created

  methods: {
    onChange: function() {
      this.menu[this.selectedId].MenuId = this.selectedId;
      this.$emit("change", this.menu[this.selectedId]);
    }
  }
};
</script>

<style>
.history-menu-view {
  position: sticky; 
  top: 2.700625rem;
  z-index: 90;
  background: #f2f2f2;
}

.history-menu-view .ly-tab-item {
  font-size: 1.125rem;
}
</style>
