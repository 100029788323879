<template>
  <div class="video-button-view">
    
    <!--點數資訊-->
    <div class="point-info" v-if="isLogin">
      <div v-if="!videoPlay.isMonth">
        您目前的點數 : {{ videoPlay.MemberPoint }} 點
        <div v-if="videoPlay.isPlay" class="state-desc">
          {{ videoPlay.stateDesc }}
        </div>
      </div>
      <div v-else>
        月繳制 : {{ videoPlay.MonthTime }}
        <div v-if="videoPlay.isPlay" class="state-desc">
          月繳制期間，不限次數觀看
        </div>
      </div>
    </div>

    <!--按鈕資訊-->
    <div class="button-group">
      <div v-if="isLogin">
        <div v-if="!videoPlay.isPlay">
          <van-row
            v-for="(item, index) in list"
            :key="index"
            type="flex"
            justify="center"
          >
            <van-button
              type="info"
              size="small"
              round
              class="video-button"
              :text="item.text"
              @click="$emit('toPay', item.key)"
            />
          </van-row>
        </div>
      </div>
      <div v-else>
        <van-row type="flex" justify="center">
          <van-button
            type="info"
            size="small"
            round
            class="video-button"
            text="免費註冊"
            @click="$emit('toRegister')"
          />
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
// VideoButtonView : 用戶點數及按鈕
// call by videoDetailView.vue

import { Cell, CellGroup, List, Row, Col, Image, Panel, Button } from "vant";

export default {
  name: "videoButtonView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Panel.name]: Panel,
    [Button.name]: Button
  },
  props: ["videoPlay"],
  data() {
    return {
      buttonList: {
        "2d": "我要付49點，確定進入",
        "49": "我要付49點，確定進入",
        "9k": "299套餐，可以直接觀看",
        "6jjj": "免費影片，可以直接觀看",
        "1z": "已付費，可以直接觀看",
        "6j": "已付費，可以直接觀看",
        "999mov": "月繳制期間，不限次數觀看",
        "99mov": "3片99點，還有2支，點我直接看片",
        "6x": "贈送影片12支，點我直接看片",
        "99m": "我要付99點，一次看3片(1片只要33點)",
        "vip1year":"鑽石會員一年免費看"
      },
      list: []
    };
  },
  computed: {
    acc() {
      return this.$store.getters.acc;
    },
    isLogin() {
      //是否登入
      return this.$isLogin();
    }
  },
  watch: {
    videoPlay: function() {
      this.createBtns();
    }
  },
  created() {},
  mounted() {
    //根據 videoPlay.state 將 buttonList 寫入 list
    this.createBtns();
  },
  methods: {
    createBtns() {
      var state = this.videoPlay.state;
      var text = "";
      var memberPoint = this.videoPlay.MemberPoint;
      var obj = {};
      this.list = [];

      switch (state) {
        case "49":
          if (memberPoint < 49) {
            obj = {
              key: "buy",
              text: "點數不足,請補充點數"
            };
          } else {
            obj = {
              key: state,
              text: this.buttonList[state]
            };
          }
          this.list.push(obj);
          break;
        case "2d":
          obj = {
            key: state,
            text: this.buttonList[state]
          };
          this.list.push(obj);
          state = "99m";
          if (memberPoint < 99) {
            obj = {
              key: "buy",
              text: "3片99點，點數不足，點此補充"
            };
          }
          else{
            obj = {
              key: state,
              text: this.buttonList[state]
            };
          }
          this.list.push(obj);
          
          break;
        case "99mov":
          text =
            "3片99點，還有" + this.videoPlay.cnt_99mov + "支，點我直接看片";
          obj = {
            key: state,
            text: text
          };
          this.list.push(obj);
          break;
        case "6x":
          /*text = "贈送影片" + this.videoPlay.cnt_6x + "支，點我直接看片";*/
          text = "贈送影片還剩" + this.videoPlay.cnt_6x + "部，點我免費看";
          obj = {
            key: state,
            text: text
          };
          this.list.push(obj);
          break;
        case "99m":
          obj = {
            key: state,
            text: this.buttonList[state]
          };
          this.list.push(obj);
          state = "2d";
          obj = {
            key: state,
            text: this.buttonList[state]
          };
          this.list.push(obj);
          break;
        default:
          obj = {
            key: state,
            text: this.buttonList[state]
          };
          this.list.push(obj);
      }
    }
  } //methods
};
</script>

<style>
.video-button-view {
  font-size: 1rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.video-button-view .point-info {
  padding: 0 0.3125rem 0.3125rem 0.3125rem;
  line-height: 1.5625rem;
}

/* .video-button-view .point-info .van-cell {
  font-size: 16px;
} */

.video-button-view .point-info .state-desc {
  color: red;
}

.video-button-view .button-group .van-row {
  padding: 0px 15px 10px 15px;
}

.video-button-view .video-button {
  font-size: 1rem;
  height: 2.5rem;
  width: 100%;
  /* background-color: #2d2d2d; */
  border-color: #2d2d2d;
  background: -webkit-linear-gradient(#444, #2d2d2d);
  background: -o-linear-gradient(#444, #2d2d2d);
  background: -moz-linear-gradient(#444, #2d2d2d);
  background: linear-gradient(#444, #2d2d2d);
}
</style>
