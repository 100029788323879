<template>
  <div class="casino-winner-view">
    <van-image src="https://m.play104.com/Img/M/Title-Competition.jpg" width="100%" lazy-load />

    <div class="winner-info">
      <div class="winner-info-table">
        <table>
          <tr v-for="item in list" :key="item.type">
            <td class="winner-nick-name">{{item.member_nick}}</td>
            <td class="winner-gift-name">
              得獎 :
              <a style="color:white" :href="item.gift_url">{{item.gift_name}}</a>
            </td>
            <td class="winner-shop-num">X {{item.shop_num}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// CasinoWinnerInfo : 比賽得獎名單
// call by CasinoView.vue
import {
  Cell,
  CellGroup,
  Icon,
  List,
  Row,
  Col,
  Image,
  Panel,
  Button,
} from "vant";

export default {
  name: "CasinoWinnerView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Panel.name]: Panel,
    [Button.name]: Button,
  },
  data() {
    return {
      list: [],
    }; //return
  }, //data
  computed: {},
  created() {},
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      //https://api.383family.com/Play104/Award
      var url = "https://api.383family.com/Play104/Award";

      var that = this;
      this.$ajax
        .get(url)
        .then(function (response) {
          let res = response.data;
          that.list = res;
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
        });
    }, //onLoad
  }, //methods
};
</script>

<style scoped>
.casino-winner-view {
  font-size: 16px;
  color: white;
  font-family: "Arial", "Helvetica", "sans-serif";
  background-color: #087363;
}

.winner-info-table table {
  border-collapse: collapse;
  width: 100%;
}

.winner-info-table th,
td {
  text-align: left;
  font-weight: normal;
  padding: 10px;
  width: 30%;
}

.winner-info-table tr:nth-child(even) {
  background-color: #25a781;
}

.winner-nick-name {
  width: 30%;
  color: yellow;
}

.winner-gift-name {
  width: 55%;
  color:white
}

.winner-shop-num {
  color: yellow;
  text-align: right;
}
</style>
