<template>
  <div class="history-video-view">
    <!-- 影片類型列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="video-info"
        v-for="item in list"
        :key="item.videoID"
        @click="toVideoDetail(item)"
      >
        <div>
          <van-image class="video-img" :src="item.photo" lazy-load />
        </div>

        <div class="video-label">
          &ensp;{{ item.videoName }}．{{ item.actorName }}．{{ item.onDate }}
        </div>

        <van-divider
          :style="{
            borderColor: 'black',
            margin: '0.3125rem 0.3125rem 0.0625rem 0.3125rem',
          }"
        ></van-divider>
      </div>
    </van-list>
  </div>
</template>

<script>
// HistoryVideoView : 我的影片分類列表
// call by HistoryView.vue,

import { List, Cell, CellGroup, Image, Divider } from "vant";

export default {
  name: "homeCategoryView",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider,
  },
  props: ["category","year"],
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0,
    };
  },
  computed: {},
  created() {}, //created
  methods: {
    onLoad() {
      this.getData();
    }, //onLoad
    getData() {
      if (this.finished) {
        return;
      }

      this.loading = true;

      var acc = this.$store.getters.acc;

      var category = this.category;
      var year = this.year;

      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(
        acc.toLowerCase() +
        this.page.toString() +
        year.toString() +
        category.toString() +
        timeKey +
        secret
      );

      var url = base_url + "/HistorySearchVideoPlus";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            acc: acc,
            Page: that.page,
            Category: category,
            Year: year,
            PostKey: postKey,
          },
        })
        .then(function (response) {
          var res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach((item) => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }

          // 加载状态结束
          that.loading = false;
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    },
    /*    toVideoDetail(item) {
      this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.videoID,
          videoName: item.videoName
        }
      });
    }, //toVideoDetail */

    toVideoDetail(item) {
      this.$router.push({
        name: "videodetailx",
        query: {
          videoDetail: JSON.stringify(item),
        },
      });
    }, //toVideoDetail

    initData() {
      return true;
    },
    handlePopstate(event) {
      this.getData();
    },
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  }, //methods
};
</script>

<style>
.history-video-view {
  font-family: "Arial", "Helvetica", "sans-serif";
}

.history-video-view .video-img {
  width: 100%;
  padding: 0.3125rem 0 0.3125rem 0;
}
.history-video-view .video-label {
  /* padding: 0 0.3125rem 0 0.3125rem; */
  font-family: "Arial", "Helvetica", "sans-serif";
}
.history-video-view .video-hr {
  margin: 0.3125rem 0.3125rem 0.0625rem 0.3125rem;
}
</style>
