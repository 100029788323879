<template>
  <div class="month-299-view">
    <!-- HEADER -->
    <HeaderView :title="'299 套餐 ( 共 ' + num + ' 片 )'" :isLeft="true" />

    <!-- 299 說明圖檔-->
    <div class="month-299-img-group">
      <van-image
        class="month-299-img"
        width="100%"
        src="http://m.a383.com/Img/Pay/299.jpg"
        lazy-load
      />
    </div>

    <!-- 用戶訂閱資訊 -->
    <MonthInfoView
      :monthInfoType="monthInfoType"
      :monthInfo="monthInfo"
      @toLogin="toLogin"
      @toRegister="toRegister"
      @toPay="toPay"
    />

    <!-- 299 套餐影片 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="video-info"
        v-for="item in list"
        :key="item.videoID"
        @click="toVideoDetail(item)"
      >
        <!-- 影片圖檔 -->
        <div>
          <van-image class="video-img" :src="item.photo" lazy-load />
        </div>

        <!-- 影片說明 -->
        <div class="video-label">
          &ensp;{{ item.videoName }}．{{ item.actorName }}．{{
            item.videoTime
          }}
        </div>

        <!-- 影片分割線 -->
        <van-divider
          :style="{
            borderColor: 'black',
            margin: '0.3125rem 0.3125rem 0.0625rem 0.3125rem',
          }"
        ></van-divider>
      </div>
    </van-list>
  </div>
</template>

<script>
// Month299View : 299 套餐
// call by NavView.vue,

import {
  List,
  Cell,
  CellGroup,
  Image,
  Divider,
  Panel,
  Dialog,
  Toast,
} from "vant";

import HeaderView from "../HeaderView/HeaderView.vue";
import MonthInfoView from "../Month299View/MonthInfoView.vue";

export default {
  name: "month299View",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Panel.name]: Panel,
    [Dialog.name]: Dialog,
    [Toast.name]: Toast,
    HeaderView,
    MonthInfoView,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,

      //片子數量
      num: 0,

      //0 未登入, 1 登入-未申裝 299, 2 登入-已申裝 299
      monthInfoType: 0,
      monthInfo: {},

      //299 第一筆 videoID,用來申請服務用
      videoID: 0,
    };
  },
  computed: {},

  created() {}, //created

  activated() {
    if (!this.$isLogin()) {
      this.monthInfoType = 0;
    } else {
      //取得用戶申裝列表
      this.getMonthInfo();
    }
  },

  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }

      this.loading = true;

      //取得 uid
      var uid = this.$store.getters.uid;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(uid + timeKey + secret);

      var url = base_url + "/Month299PLus";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            uid: uid,
            PostKey: postKey,
          },
        })
        .then(function (response) {
          let res = response.data.data;
          //console.log("month299 res:",res)

          //一次載入
          that.list = res;
          that.num = res.length;

          //設定第一筆 videoID, 申請服務用
          that.videoID = that.list[0].videoID;

          //沒有更多
          that.finished = true;

          // 加载状态结束
          that.loading = false;
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    toVideoDetail(item) {
      if (this.monthInfoType == 0) {
        return;
      }

      /* this.$router.push({
        name: "videodetail",
        query: {
          videoID: item.product_id,
          videoName: item.product_name
        }
      }); */

      this.$router.push({
        name: "videodetailx",
        query: {
          videoDetail: JSON.stringify(item),
        },
      });
    }, //toVideoDetail

    initData() {
      //初始化變數
      return true;
    }, //initData

    getMonthInfo() {
      //取得用戶訂閱資料

      var acc = this.$store.getters.acc;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(acc.toLowerCase() + timeKey + secret);

      var url = base_url + "/getMonthInfo";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            acc: acc,
            PostKey: postKey,
          },
        })
        .then(function (response) {
          let res = response.data.data;
          that.monthInfo = res;
          if (that.monthInfo.is299) {
            that.monthInfoType = 2;
          } else {
            that.monthInfoType = 1;
          }
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
        });
    },

    toLogin() {
      var env = process.env.VUE_APP_ENV;
      var url = "";
      if (env == "prod") {
        //正式環境登入
        url =
          "http://api.383family.com/Account/MemLogin?url=" +
          this.$route.fullPath;
        window.location = url;
      } else {
        //測試用登入
        url = "/login1?url=" + this.$route.fullPath;
        this.$router.push(url);
      }
    }, //toLogin

    toRegister() {
      //註冊後回轉
      let url =
        "https://api.383family.com/Account/MemReg?url=" + this.$route.fullPath;
      window.location = url;
    }, //toRegister

    toPay() {
      //付費, 並取得新 monthInfo, monthInfoType

      Toast.loading({
        className: "video-toast",
        icon: "/img/loader.gif",
        type: "loading",
        duration: 0,
        forbidClick: true,
        //onClose: that.closeLoding // 關閉後的回調
      });

      var acc = this.$store.getters.acc;
      var vid = this.videoID;

      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(acc.toLowerCase() + vid + timeKey + secret);

      var url = base_url + "/Buy299";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            acc: acc,
            vid: vid,
            PostKey: postKey,
          },
        })
        .then(function (response) {
          var res = response.data.data;
          var message = response.data.message;
          if (message == "success") {
            //扣款成功,重新整理 monthInfo, monthInfoType
            that.monthInfo = res;
            if (res.is299) {
              that.monthInfoType = 2;
            }
          } else {
            Dialog({ message: message });
          }
          //清除輕提示
          Toast.clear();
        })
        .catch(function (err) {
          // 出錯
          console.log(err);
          //清除輕提示
          Toast.clear();
        });
    }, //toPay299
  }, //methods
};
</script>

<style>
.month-299-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.month-299-view .video-img {
  width: 100%;
  padding: 0.3125rem 0 0.3125rem 0;
}

.month-299-view .video-label {
  /* padding: 0 0.3125rem 0 0.3125rem; */
  font-family: "Arial", "Helvetica", "sans-serif";
}

.month-299-view .video-hr {
  margin: 0.3125rem 0.3125rem 0.0625rem 0.3125rem;
}
</style>
