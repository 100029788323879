<template>
  <div class="video-list-view">

    <!-- 影片列表 -->
    <van-list>

      <div class="video-list-info" v-for="item in list" :key="item.videoID" @click="$emit('click',item)">
        <van-image class="video-img" :src="item.photo" lazy-load />
        <!-- <van-image class="video-img" src="img/default.png" lazy-load /> -->

        <label class="video-label">
          {{item.videoName}}．{{item.actorName}}．{{item.onDate}}
        </label>

         <van-divider
          :style="{ borderColor: 'black',margin: '0.3125rem 0.3125rem 0.0625rem 0.3125rem' }"
        ></van-divider>

      </div>
    </van-list>
  </div>
</template>

<script>
// VideoListView : 影片列表
// call by VideoDetailView.vue
// 一次載入
import { List, Cell, CellGroup, Image, Divider } from "vant";

export default {
  name: "videoListView",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Divider.name]: Divider
  },
  props: ["list"],
  data() {
    return {
      //list: [],
    };
  },
  methods: {
  } //methodss
};
</script>

<style>

.video-list-view .video-list-info .video-img {
  width: 100%;
  padding: .3125rem 0 .3125rem 0;
}

.video-list-view .video-label {
  padding: 0 0.3125rem 0 0.3125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}
.video-list-view .video-hr {
  margin: 0.3125rem 0.3125rem 0.0625rem 0.3125rem;
}
</style>
