<template>
  <div class="article-item-view">
    <van-row @click="toArticleContent(list[0].Id)" align="center">
      <van-col span="9">
        <van-image class="article-img" :src="list[0].Photo" lazy-load />
      </van-col>

      <van-col span="13" class="article-desc">
        <div class="article-desc-data">
          {{ list[0].Title }}
          <br />
          發佈 : {{ list[0].PostDate }}
          <br />
          發文 : {{ list[0].Author }}
          <br />
          <div class="desc">{{ list[0].Desc }}</div>
        </div>
        <span></span>
      </van-col>

      <van-col span="2" class="article-arrow">
        <van-icon name="arrow" class="article-arrow-data" />
        <span></span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
// ArticleListView : 最新情報,單筆
// call by HomeView

import { Cell, CellGroup, List, Row, Col, Image, Icon } from "vant";

export default {
  name: "articleListView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon
  },
  data() {
    return {
      list: [
        {
          Photo: "",
          Title: "",
          PostDate: "",
          Author: "",
          Desc: ""
        }
      ]
    };
  }, //data
  created() {
    var page = 1;

    var secret = process.env.VUE_APP_SECRET;
    var base_url = process.env.VUE_APP_BASE_URL;
    var timeKey = this.$timeKey();
    var postKey = this.$md5(page.toString() + timeKey + secret);

    // 獲取最新情報數據
    var url = base_url + "/ArticleList";

    var that = this;
    this.$ajax
      .get(url, {
        params: {
          Page: page,
          PostKey: postKey
        }
      })
      .then(function(response) {
        // 獲取數據成功
        that.list = response.data.data;
      })
      .catch(function(err) {
        // 出錯
        console.log(err);
      });
  }, //created
  methods: {
    toArticleContent(id) {
      this.$router.push({
        name: "articlecontent",
        query: {
          Id: id
        }
      });
    } //toArticleContent
  } //method
}; //default
</script>

<style>
.article-item-view .article-img {
  height: 7.8125rem;
  width: 7.8125rem;
  margin: 0.3125rem 0.3125rem 0 0.3125rem;
}

.article-item-view .article-desc {
  height: 8.2rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
}

.article-item-view .article-desc .desc {
  font-size: 0.8125rem;
  font-weight: lighter;
}

.article-item-view .article-desc .article-desc-data {
  display: inline-block;
  vertical-align: middle;
}

.article-item-view .article-desc span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.article-item-view .article-arrow {
  height: 8.2rem;
  float: left;
}

.article-item-view .article-arrow .article-arrow-data {
  display: inline-block;
  vertical-align: middle;
}

.article-item-view .article-arrow span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
</style>
