<template>
  <div class="article-view">
    <!-- HEADER -->
    <HeaderView title="AV 情報" :isLeft="true" />

    <!-- SEARCH Box -->
    <div class="article-search">
      <van-search
        v-model="search"
        placeholder="請輸入搜索關鍵字"
        show-action
        shape="round"
        @search="onSearch"
        class="search-box"
      >
        <div slot="action" @click="onSearch">搜索</div>
      </van-search>
    </div>

    <!-- 最新情報列表 -->
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <ArticleItemView
        v-for="item in list"
        :key="item.Id"
        :item="item"
        @click="toArticleContent"
      ></ArticleItemView>
    </van-list>
    <GoTopView></GoTopView>
  </div>
</template>

<script>
// ArticleView : 最新情報,多筆
// call by NavView.vue

import { List, Search } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";
import ArticleItemView from "../ArticleListView/ArticleItemView.vue";
import GoTopView from "../ScrollMenuView/GoTopView.vue";

export default {
  name: "articleView",
  components: {
    [List.name]: List,
    [Search.name]: Search,
    HeaderView,
    ArticleItemView,
    GoTopView
  },
  data() {
    return {
      title: "最新情報",
      navViewShow: false, //顯示分頁表頭

      list: [],
      loading: false,
      finished: false,
      page: 0,

      search: "" //搜尋關鍵字
    };
  },
  computed: {},
  created() {
    // 發送標題
    this.$emit("onTitle", this.title);
    this.$emit("onNavShow", this.navViewShow);
  }, //created
  methods: {
    onLoad() {
      if (this.finished) {
        return;
      }
      this.loading = true;
      this.page++;
      var secret = process.env.VUE_APP_SECRET;
      var base_url = process.env.VUE_APP_BASE_URL;
      var timeKey = this.$timeKey();
      var postKey = this.$md5(this.page.toString() + timeKey + secret);

      //let url ="http://api.383family.com/A383/ArticleList?Page=1&PostKey=b8f4b06737989d7fa2702726aff98f64"
      //var url = "http://api.383family.com/A383/ArticleList";
      var url = base_url + "/ArticleList";

      var that = this;
      this.$ajax
        .get(url, {
          params: {
            Page: that.page,
            PostKey: postKey
          }
        })
        .then(function(response) {
          let res = response.data.data;

          if (res.length > 0) {
            //把 data 加入 list
            res.forEach(item => {
              that.list.push(item);
            });
            //小於 30 筆,沒有下一頁
            if (res.length < 10) {
              that.finished = true;
            }
          } else {
            that.finished = true;
          }

          // 加載狀態結束
          that.loading = false;
        })
        .catch(function(err) {
          // 出錯
          console.log(err);
          that.loading = false;
        });
    }, //onLoad

    toArticleContent(id) {
      this.$router.push({
        name: "articlecontent",
        query: {
          Id: id
        }
      });
    }, //toArticleContent

    initData() {
      return true;
    }, // initData

    onSearch() {
      if (this.search) {
        var search = this.search;
        this.$router.push({
          name: "article_search",
          query: {
            Search: search
          }
        });
      }
    } // onSearch
  }, //methods

  activated() {
    //activated
  }
};
</script>

<style>
.article-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}
.article-view .article-search .van-search__action {
  font-size: 1rem;
}
.article-view .article-search .van-field__control {
  font-size: 1rem;
}

</style>
