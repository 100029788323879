<template>
  <div class="login-view">
    <!-- HEADER -->
    <HeaderView title="登入" />
    <div>uid:{{ uid }}</div>
    <div>acc:{{ acc }}</div>
    <div>nick:{{ nick }}</div>
    <div>from:{{ from }}</div>
    <div>url:{{ url }}</div>
    <div>icon:{{ icon }}</div>
  </div>
</template>

<script>
// loginView : 登入 url
// call by App.vue
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "loginView",
  components: {
    HeaderView,
  },
  data() {
    return {
      uid: "",
      acc: "",
      nick: "",
      from: "",
      url: "",
      icon: "",
      pwd: "",
      userInfo: {},
    };
  },
  computed: {},
  created() {
    this.uid = this.$route.query.uid;
    this.acc = this.$route.query.acc;
    this.nick = this.$route.query.nick;
    this.from = this.$route.query.from;
    this.url = this.$route.query.url;
    this.icon = this.$route.query.icon;
    this.pwd = this.$route.query.pwd;

    this.userInfo.uid = this.$route.query.uid;
    this.userInfo.acc = this.$route.query.acc;
    this.userInfo.nick = this.$route.query.nick;
    this.userInfo.icon = this.$route.query.icon;
    this.userInfo.pwd = this.$route.query.pwd;

    //store uid to store
    //this.$store.dispatch("setUid", this.uid);
    this.$store.dispatch("setUserInfo", this.userInfo);

    //除 299, 499, buy 外, 其餘登入後都導到首頁
    if (
      this.url != "/month299" &&
      this.url != "/month499" &&
      this.url != "/buy"
    ) {
      this.url = "/home";
    }

    this.url = this.$tsURL(this.url);
    this.$router.replace(this.url);
  },
};
</script>

<style>
.login-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}
</style>
