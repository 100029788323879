<template>
  <div class="header-view">
    <van-nav-bar
      :title="title"
      :left-arrow="isLeft"
      @click-left="$router.go(-1)"
      fixed
    >
    </van-nav-bar>
  </div>
</template>

<script>
// HeaderView : 一般表頭
// call by
import { Cell, CellGroup, NavBar, Icon, Tabbar, TabbarItem } from "vant";
export default {
  name: "headerView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  props: {
    title: {
      type: String,
      default: "title"
    },
    isLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //isLeft:true,
    };
  },
  computed: {},
  created() {}
};
</script>

<style>
.header-view {
  position: fixed;
  top: 0;
  height: 2.8125rem;
  z-index: 100;
  background: red;
  font-family: "Arial", "Helvetica", "sans-serif";
}

.header-view .van-nav-bar {
  height: 2.8125rem;
  line-height: 2.8125rem;
  font-size: 1.5rem;
  font-weight: 900;
  color: black;
}

.header-view .van-nav-bar .van-nav-bar__title {
  font-size: 1.125rem;
  font-weight: 900;
  color: black;
}

.header-view .van-nav-bar .van-icon {
  font-size: 1.5rem;
  font-weight: 900;
  color: black;
}

</style>
