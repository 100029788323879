<template>
  <div class="video-data-view">
    <div class="video-data-video-detail" v-if="videoDetail">
      <ul>
        <li>VideoDetail :</li>
        <!--         <li>isPlay : {{ videoDetail.isPlay }}</li>
        <li>isMonth : {{ videoDetail.isMonth }}</li>
        <li>MonthTime : {{ videoDetail.MonthTime }}</li>
        <li>MemberPoint : {{ videoDetail.MemberPoint }}</li> -->
        <li>series : {{ videoDetail.series }}</li>
        <li>preview : {{ videoDetail.preview.length }}</li>
        <li>iphone : {{ this.$device.iphone }}</li>
        <li>ipad : {{ this.$device.ipad }}</li>
        
        <li>isMobile : {{ this.deviceInfo.isMobile }}</li>
        <li>isIOS : {{ this.deviceInfo.isIOS }}</li>
        <li>deviceType : {{ this.deviceInfo.deviceType }}</li>

        <!--         <li>state : {{ videoDetail.state }}</li>
        <li>stateDesc : {{ videoDetail.stateDesc.substr(0, 10) }}</li>
        <li>videoURL : {{ videoDetail.videoURL.substr(0, 10) }}</li>
        <li>cnt_99mov : {{ videoDetail.cnt_99mov }}</li>
        <li>cnt_6x : {{ videoDetail.cnt_6x }}</li> -->
      </ul>
    </div>

    <!--<div class="video-data-play-video" v-if="playVideo">
      <ul>
        <li>playVideo:</li>
        <li>playVideo.data:{{ playVideo.data }}</li>
      </ul>
    </div> -->
  </div>
</template>

<script>
// videoDataView : 顯示影片參數
// call by VideoDetailView.vue

import { Cell, CellGroup, Row, Col, Image, Panel } from "vant";

export default {
  name: "videoDataView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Panel.name]: Panel
  },
  props: ["videoDetail", "videoPlay"],
  data() {
    return {
      deviceInfo : {}
    };
  },
  computed: {
    videoURL() {
      //return decodeURIComponent(this.playVideo.data);
      return decodeURIComponent(this.videoDetail.videoURL);
    }
  },
  created() {},
  mounted() {
    this.deviceInfo = this.getDeviceInfo()
  },
  methods: {
    getDeviceInfo: function() {
      //取得裝置類型
      var isMobile = false;
      var isIOS = false;
      var deviceType = "";
      var deviceInfo = new Object();
      var userAgentInfo = navigator.userAgent.toLowerCase();
      var Agents = [
        "android",
        "iphone",
        "symbianos",
        "windows phone",
        "ipad",
        "ipod"
      ];
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) >= 0) {
          deviceType = Agents[v];
          isMobile = true;
        }
      }

      // iPad 處理
      isIOS =
        (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1));

      deviceInfo["isMobile"] = isMobile || isIOS;
      deviceInfo["isIOS"] = isIOS;
      deviceInfo["deviceType"] = deviceType;
      return deviceInfo;
    },


  } //methods
};
</script>

<style scoped>
.video-data-view {
  padding: 0.3125rem;
  line-height: 1.5625rem;
  font-size: 1rem;
}
</style>
