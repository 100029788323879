<template>
  <div class="video-image-view">

    <div class="video-media" v-if="videoPlay.isPlay" :key="('media-'+videoDetail.videoID)">
      <video
        preload="none"
        class="video-js vjs-default-skin vjs-16-9"
        controls
        :poster="videoDetail.photo"
      >
        <source :src="videoURL" type="application/x-mpegURL" label="720p" />
      </video>
    </div>

    <div class="video-photo" v-else>
       <van-image width="100%" :src="videoDetail.photo" lazy-load />
    </div>
  </div>
</template>

<script>
// videoImageView : 顯示影片或是圖檔
// call by VideoDetailView.vue

import { Cell, CellGroup, Row, Col, Image, Panel } from "vant";

export default {
  name: "videoImageView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Panel.name]: Panel
  },
  props: ["videoDetail", "videoPlay"],
  data() {
    return {};
  },
  computed: {
    videoURL() {
      //return decodeURIComponent(this.playVideo.data);
      return decodeURIComponent(this.videoPlay.videoURL);
    }
  },
  created() {},
  mounted() {
    this.player();
  },
  updated() {
    this.player();
  },
  methods: {
    player() {
      var player = window.hola_player({
        volume: true,
        autoplay: false
      });
    } //player
  }, //methods
  activated() {
    this.player(); 
  }
};
</script>

<style scoped>
.video-image-view {
  padding: 0 0 0.3125rem 0;
  width: 100%;
}

.video-image-view .video-photo {
  width: 100%;
}
</style>
