<template>
  <div class="scroll-menu-view">
    <!--<van-tabs v-model="active" type="line">
      <van-tab :title="item.MenuName" v-for="(item,index) in menu" :key="index"></van-tab>
    </van-tabs>-->

    <ly-tab
      v-model="selectedId"
      :items="menu"
      :options="options"
      @change="onChange"
    ></ly-tab>
  </div>
</template>

<script>
// ScrollMenuView : 橫向滾動選單
// call by HomeView.vue

import { Tab, Tabs } from "vant";

export default {
  name: "scrollMenuView",
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  props: {
    menuId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: 0,

      menu: [],
      selectedId: 0,
      options: {
        activeColor: "#FF44AA",
        labelKey: "MenuName"
        // 可在這裡指定labelKey為你數據裡文字對應的字段
      }
    };
  },

  watch: {
    menuId() {
      this.selectedId = this.menuId;
    }
  }, //watch

  created() {
    this.selectedId = this.menuId;

    var secret = process.env.VUE_APP_SECRET;
    var base_url = process.env.VUE_APP_BASE_URL;
    var timeKey = this.$timeKey();
    var postKey = this.$md5(timeKey + secret);

    // 獲取 ScrollMenu Data
    var url = base_url + "/ScrollMenu";

    var that = this;
    this.$ajax
      .get(url, {
        params: {
          PostKey: postKey
        }
      })
      .then(function(response) {
        // 獲取數據成功

        that.menu = response.data.data;
      })
      .catch(function(err) {
        // 出錯
        console.log(err);
      });
  }, //created
  methods: {
    onChange: function() {
      this.menu[this.selectedId].MenuId = this.selectedId;
      this.$emit("click", this.menu[this.selectedId]);
    }
  }
};
</script>

<style>
.scroll-menu-view {
  /* position: sticky; */
  top: 2.700625rem;
  z-index: 90;
  background: #f2f2f2;
}

.scroll-menu-view .ly-tab-item {
  font-size: 1.125rem;
}
</style>
