<template>
  <div class="csr-view">
    <!-- HEADER -->
    <HeaderView title="客服中心" :isLeft="true" />

    <!--<div class="csr-info">
      <van-panel title="客服電話" icon="service-o">
        <div class="csr-time">
          週一至週五<br />
          早上 9:00 ~ 晚上 12:00 <br />
          0926-193-055 轉網路客服專員<br />
        </div>
      </van-panel>
    </div>-->

    <div class="line-info">
      <van-panel title="線上客服即時通訊" icon="chat-o">
        <div class="line-img">
          <a href="http://line.me/ti/p/ThdYhQO2Xj">
            <img src="http://www.a383.com/images/line_new.gif" />
          </a>
        </div>
      </van-panel>
    </div>

    <div class="ticket-info" v-if="isLogin">
      <van-panel title="問題反映" icon="records">
        <div class="ticket">
          <van-form @submit="onSubmit">
            <van-cell-group>
              <van-field
                v-model="name"
                label="您的姓名"
                placeholder=""
                :rules="[{ required: true, message: '請輸入姓名' }]"
              />
              <van-field
                v-model="tel"
                label="手機號碼"
                placeholder=""
                :rules="[{ required: true, message: '請輸入連絡電話' }]"
              />
              <van-field
                v-model="email"
                label="電子信箱"
                placeholder=""
                :rules="[
                  { required: true, message: '請輸入電子信箱' },
                  { pattern, message: '請輸入正確電子信箱格式' },
                ]"
              />
              <van-field
                v-model="note"
                rows="3"
                autosize
                label="問題說明"
                type="textarea"
                maxlength="400"
                placeholder=""
                show-word-limit
                :rules="[{ required: true, message: '請輸入問題說明' }]"
              />
            </van-cell-group>
            <div class="form-check">
                  您希望客服以下列何種方式回覆：
                  <br />
                  <input type="checkbox" name="AnswerWayInt" class="form-check-input" value="1" id="chkEmail" checked />
                  <label class="form-check-label" for="chkEmail">Email</label>
                  <input type="checkbox" name="AnswerWayInt" class="form-check-input" value="2" id="chkMob" checked />
                  <label class="form-check-label" for="chkMob">手機簡訊</label>
              </div>
              <input type="hidden" value="" name="IntAnswerWay" id="IntAnswerWay" />
            <div class="ticket-button-group">
              <van-button
                type="info"
                size="small"
                round
                class="ticket-button"
                text="確定送出"
                native-type="submit"
              />
            </div>
          </van-form>
        </div>
      </van-panel>
    </div>
  </div>
</template>

<script>
// CsrView : 客服中心
// call by App.vue
import {
  Cell,
  CellGroup,
  List,
  Row,
  Col,
  Image,
  Panel,
  Field,
  Button,
  Toast,
  Form,
  Dialog,
} from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "csrView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Panel.name]: Panel,
    [Field.name]: Field,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Dialog.name]: Dialog,
    HeaderView,
  },
  data() {
    return {
      name: "",
      tel: "",
      email: "",
      note: "",
      answerway: 0,
      pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
    };
  },
  computed: {
    isLogin: function() {
      var acc = this.$store.getters.acc;
      //if (acc) {
        return true;
      //} else {
      //  return false;
      //}
    },
  },
  created() {},
  methods: {
    onSubmit: function() {
      Toast.loading({
        mask: false,
        message: "資料處理中...",
        duration: 0,
      });

      var txtName = this.name;
      var txtTel = this.tel;
      var txtEMail = this.email;
      var txaNote = this.note;
      var IntAnswerWay = this.answerway;
      var txtMemberID = this.$store.getters.uid;
      var txtUrl = "https://www.s383.com/top/readme/servicechoise.asp";
      var SubjectName = "Live 影音秀客服信件(tw)";

      var apiURL = "https://api.383family.com/Service/FAQ";

      var AwInt = document.querySelectorAll('input[name=AnswerWayInt]:checked');
      if(AwInt.length <= 0){
          alert('請勾選回覆方式!!');
          Toast.clear();
          return false;
      }else{
        var AnswerWay = 0;
        AwInt.forEach(function (input) {
          if (AnswerWay == 0) {
            AnswerWay = parseInt(input.value);
          } else {
            AnswerWay += parseInt(input.value);
          }
        });
        if (AnswerWay == 3) {
          AnswerWay = 0;
        }
        document.getElementById('IntAnswerWay').value = AnswerWay;
      }

      const formData = new FormData();
      formData.append("txtName", txtName);
      formData.append("txtTel", txtTel);
      formData.append("txtEMail", txtEMail);
      formData.append("txaNote", txaNote);
      formData.append("txtMemberID", txtMemberID);
      formData.append("txtUrl", txtUrl);
      formData.append("SubjectName", SubjectName);
      formData.append("IntAnswerWay", IntAnswerWay);

      var that = this;
      this.$ajax
        .post(apiURL, formData)
        .then(function(response) {
          console.log(response);
          //清除輕提示
          Toast.clear();
          Dialog.alert({
            message: "已經將您的問題反映給客服部，<br>我們將儘速為您處理！",
          }).then(() => {
            //轉到 Home
            that.$router.push("home");
          });
        })
        .catch(function(err) {
          console.log(err);
          //清除輕提示
          Toast.clear();
        });
    },
  },
};
</script>

<style>
.csr-view {
  padding-top: 2.8125rem;
  padding-bottom: 3.125rem;
  font-family: "Arial", "Helvetica", "sans-serif";
  font-size: 1rem;
}

.csr-info {
  padding: 0.625rem;
  border-radius: 0.625rem;
}

.csr-info .van-cell {
  font-size: 1rem;
}

.csr-time {
  padding: 0.9375rem;
  line-height: 1.5625rem;
}

.line-info {
  padding: 0px 10px 10px 10px;
}

.line-info .van-cell {
  font-size: 1rem;
}

.line-info .line-img {
  padding: 0.9375rem;
}

.ticket-info {
  padding: 0px 10px 10px 10px;
}

.ticket-info .van-cell {
  font-size: 1rem;
}

.ticket-button-group {
  height: 30px;
  font-size: 1rem;
  padding: 0rem 1rem 1.25rem 1rem;
}

.ticket-button-group .ticket-button {
  /* display: inline-block; */
  /* vertical-align: middle; */
  font-size: 1rem;
  height: 2.5rem;
  width: 100%;
  background-color: #2d2d2d;
  border-color: #2d2d2d;
  background: -webkit-linear-gradient(#444, #2d2d2d);
  background: -o-linear-gradient(#444, #2d2d2d);
  background: -moz-linear-gradient(#444, #2d2d2d);
  background: linear-gradient(#444, #2d2d2d);
}

.csr-view .van-field__control {
  background-color: whitesmoke;
}

.form-check {
  padding: 1rem 1rem 0.25rem 1rem;
  font-size: 1rem;
  text-align: center;
}
</style>
