<template>
  <div class="article-tag-view">
    <!--Title-->
    <!-- <div class="article-tag-title">
      <h2>相關搜尋</h2>
    </div> -->

    <!-- <van-cell class="article-tag-title" title="相關搜尋" icon="location-o"></van-cell> -->

    <!-- Search Tag -->
    <div class="article-tag-group">
      <van-tag
        round
        v-for="(item,index) in tags"
        :key="index"
        size="medium"
        plain
        class="article-tag"
        @click="$emit('click',item)"
      >{{item}}</van-tag>
    </div>
  </div>
</template>

<script>
// ArticleTagView : 最新情報相關搜尋
// call by ArticleContentView.vue
import { Tag, Cell, CellGroup } from "vant";

export default {
  name: "articleTagView",
  components: {
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  props: ["tags"],
  data() {
    return {
      list: []
    };
  }
};
</script>

<style>
.article-tag-view {
  padding: 0.625rem;
}

.article-tag-view .article-tag-title {
  font-size: 1rem;
  background: #f2f2f2;
}

.article-tag-view .article-tag-group {
  padding: 10px;
}

.article-tag-view .van-tag--default {
  margin: 0.3125rem;
}

.article-tag-view .van-tag--medium {
  font-size: 1rem;
}

.article-tag-view .article-tag-group .article-tag{
  color: #222;
  border-color: 1px solid #ccc;
  background: -webkit-linear-gradient(#fff,#f1f1f1);
  background: -o-linear-gradient(#fff,#f1f1f1);
  background: -moz-linear-gradient(#fff,#f1f1f1);
  background: linear-gradient(#fff,#f1f1f1);
}

</style>