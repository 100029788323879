<template>
  <div class="av-top-item-view">
    <van-row @click="$emit('click', item.AvName)">
      <van-col span="6" class="av-img-group">
        <van-image class="av-img" :src="item.Photo" lazy-load />
        <span></span>
      </van-col>

      <van-col span="16" class="av-desc">
        <div class="av-desc-data">
          排名 : {{ item.order_id }}
          <br />
          姓名 : {{ item.AvName }}
        </div>
        <span></span>
      </van-col>

      <van-col span="2" class="av-arrow">
        <van-icon name="arrow" class="av-arrow-data" />
        <span></span>
      </van-col>
    </van-row>

    <van-divider
      :style="{
        borderColor: 'black',
        margin:'0px'
      }"
    ></van-divider>

  </div>
</template>

<script>
// AvTopItemView : 女優資訊
// call by AvTopView.vue

import { Cell, CellGroup, List, Row, Col, Image, Icon, Divider } from "vant";

export default {
  name: "AvTopItemView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Divider.name]: Divider
  },
  data() {
    return {};
  },
  props: ["item"],
  created() {}, //created
  methods: {} //methods
};
</script>

<style scoped>
.av-top-item-view .av-img-group {
  height: 6.25rem;
}

.av-top-item-view .av-img-group .av-img {
  display: inline-block;
  vertical-align: middle;
}

.av-top-item-view .av-img-group span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.av-top-item-view .av-desc {
  height: 6.25rem;
  font-size: 1.125rem;
}

.av-top-item-view .av-desc .av-desc-data {
  display: inline-block;
  vertical-align: middle;
}
.av-top-item-view .av-desc span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.av-top-item-view .av-arrow {
  height: 6.25rem;
  float: left;
}

.av-top-item-view .av-arrow .av-arrow-data {
  display: inline-block;
  vertical-align: middle;
}
.av-top-item-view .av-arrow span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
</style>
