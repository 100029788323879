<template>
  <div class="category-ad-view">
    <!-- CASINO廣告 -->
    <div class="category-ad-group">
      <div v-if="$isLogin()">
        <van-image
          src="https://m.a383.com/Img/Banner/hogaming_banner1.gif"
          @click="toHoGaming"
          class="category-ad-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
// CategoryAdView : Category廣告
// call by CategoryView.vue

import { Image } from "vant";

export default {
  name: "categoryAdView",
  components: {
    [Image.name]: Image,
  },
  data() {
    return {};
  },
  computed: {},
  created() {}, //created
  mounted() {},
  methods: {
    toHoGaming() {
      //轉到會員中心
      if (!this.$isLogin()) {
        Dialog.alert({
          message: "請先登入會員！",
        }).then(() => {
          // on close
        });
      } else {
        var secret = process.env.VUE_APP_SECRET;
        var base_url = process.env.VUE_APP_BASE_URL;
        var timeKey = this.$timeKey();
        var postKey = this.$md5(
          timeKey + secret
        );

        var mid = this.$store.getters.uid;
        var url = base_url + "/ToHoGame?Acc="+mid+"&PostKey="+postKey;
        window.open(url);
      }
    },
    toCasino() {
      //this.$router.push("/casino");
      window.open("https://m.play104.com");
    }, //toAd
  }, //methods
};
</script>

<style>
.category-ad-view .category-ad-group .category-ad-img {
  width: 100%;
}
</style>
