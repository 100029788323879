<template>
  <div class="credit-view">
    <!-- HEADER -->
    <HeaderView title="累積紅利" :isLeft="true" />
    <!-- <HomeHeaderView title="A383 影音城" /> -->

    <!--CARD BONUS 入口-->
    <div class="card-bonus-img">
      <van-image src="https://m.s383.com/site/img/m383_card_bonus.jpg" width="100%" lazy-load />
    </div>
  </div>
</template>

<script>
// CreditView : Credit
// call by BuyView.vue

import { Image } from "vant";
import HeaderView from "../HeaderView/HeaderView.vue";

export default {
  name: "categoryView",
  components: {
    [Image.name]: Image,
    HeaderView,
  },
  data() {
    return {};
  },
  computed: {},
  created() {
    // 發送標題
    this.$emit("onTitle", this.title);
    this.$emit("onNavShow", this.navViewShow);
  }, //created
  methods: {}, //methods
};
</script>

<style>
.credit-view {
  /* padding-top: 2.8125rem; */
  padding-bottom: 3.125rem;
  font-size: 1rem;
  font-family: "Arial", "Helvetica", "sans-serif";
}
.card-bonus-img {
  padding-top: 2.8125rem;
}
</style>
