<template>
  <div class="month-info-view">
    <div class="user-info">
      <van-panel title="用戶資訊" icon="smile-o">
        <!--未登入-->
        <div v-if="monthInfoType == 0">
          <div class="user-info-desc">您還未登入</div>
          <div class="month-button-group">
            <van-row>
              <van-button
                type="info"
                size="small"
                round
                class="month-button"
                text="會員登入"
                @click="$emit('toLogin')"
              />
            </van-row>
            <van-row>
              <van-button
                type="info"
                size="small"
                round
                class="month-button"
                text="會員註冊"
                @click="$emit('toRegister')"
              />
            </van-row>
          </div>
        </div>

        <!--已登入,未申裝-->
        <div v-if="monthInfoType == 1">
          <div class="user-info-desc">
            <ul>
              <li>親愛的 {{ acc }} 會員您好</li>
              <li>您的點數剩餘 {{ monthInfo.Point }} 點</li>
              <li>您尚未啟用服務</li>
            </ul>
          </div>
          <div class="month-button-group">
            <van-row>
              <van-button
                type="info"
                size="small"
                round
                class="month-button"
                text="我同意，立刻購買"
                @click="toPay"
              />
            </van-row>
          </div>
        </div>

        <!--已登入,已申裝-->
        <div v-if="monthInfoType == 2">
          <div class="user-info-desc">
            <ul>
              <li>親愛的 {{ acc }} 會員您好</li>
              <li>您的點數剩餘 {{ monthInfo.Point }} 點</li>
              <li>觀賞影片日期 {{ monthInfo.DateVIP }}</li>
            </ul>
          </div>
          <div class="month-button-group">
            <van-row>
              <van-button
                type="info"
                size="small"
                round
                class="month-button"
                text="取消月繳制"
                @click="toPay"
              />
            </van-row>
          </div>
        </div>
      </van-panel>
    </div>
  </div>
</template>

<script>
// monthInfoView : 用戶包月資訊
// call by month299Vide.vue

import { Cell, CellGroup, List, Row, Col, Image, Panel, Button } from "vant";

export default {
  name: "monthInfoView",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Panel.name]: Panel,
    [Button.name]: Button
  },
  props: ["monthInfoType", "monthInfo"],
  data() {
    return {};
  },
  computed: {
    acc() {
      return this.$store.getters.acc;
    }
  },
  created() {},
  methods: {
    toPay() {
      //申請或取消 499, 用跳轉方式處理
      var memid = this.$store.getters.uid;
      var url = "https://www.love104.com/bill/pepay/ibon/shop.asp";
      url =
        url +
        "?selPoint=499&seltt=PD-CREDIT-TSCB-AMS&selCountryCode=+886&memid=" +
        memid;
      //window.open(' 新視窗的網址 ', '新視窗的名稱', config='height=高度,width=寬度');
      window.open(url);
    }
  } //methods
};
</script>

<style scoped>
.month-info-view {
  font-family: "Arial", "Helvetica", "sans-serif";
}

.month-info-view .user-info {
  padding: 0.625rem;
}

.month-info-view .user-info .van-cell {
  font-size: 1rem;
}

.month-info-view .user-info-desc {
  padding: 0.9375rem;
  line-height: 1.5625rem;
}

.month-info-view .month-button-group {
  padding: 0px 15px 0px 15px;
}

.month-info-view .month-button-group .van-row {
  padding: 0px 0px 10px 0px;
}

.month-info-view .month-button {
  font-size: 1rem;
  height: 2.5rem;
  width: 100%;
  background-color: #2d2d2d;
  border-color: #2d2d2d;
  background: -webkit-linear-gradient(#444, #2d2d2d);
  background: -o-linear-gradient(#444, #2d2d2d);
  background: -moz-linear-gradient(#444, #2d2d2d);
  background: linear-gradient(#444, #2d2d2d);
}
</style>
