<template>
  <div id="app">
    <!-- 頭部-導航欄 -->
    <!-- <van-nav-bar :title="title" fixed v-show="navViewShow" /> -->

    <!-- 主體內容 -->
    <!-- 路由規則匹配到的組件 渲染位置 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" :toHome="toHome" @onTitle="getTitle"
        @onNavShow="getShow" @onActive="setActive" @onBootom="setBootom" ></router-view>
    </keep-alive>

    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" @onTitle="getTitle" @onNavShow="getShow"
      @onActive="setActive"></router-view>

    <!-- 底部通欄 -->
    <van-tabbar v-if="bootom" v-model="active" active-color="red" @change="onClickTabbarItem">
      <van-tabbar-item icon="home-o" to="/home" @click="scrollToTop">首頁</van-tabbar-item>
      <van-tabbar-item icon="filter-o" to="/category">分類</van-tabbar-item>
      <van-tabbar-item icon="cart-o" to="/buy">購買</van-tabbar-item>
      <van-tabbar-item icon="location-o" to="/nav">導航</van-tabbar-item>
      <van-tabbar-item icon="contact" to="/mine">我的</van-tabbar-item>
    </van-tabbar>
    <div id="myModal" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <span class="close">×</span>
        <h4>把A383添加到主畫面</h4>
        <p>利用手機主畫面上的捷徑快速訪問A383。</p>
        <p style="display: flex;align-items: flex-end;">1.點選 “分享” <span class="material-symbols-outlined"
            style="color: dodgerblue;padding-left: 4px;">ios_share</span></p>
        <p style="display: flex;align-items: flex-end;">2.點選 “加入主畫面” <span class="material-symbols-outlined"
            style="padding-left: 4px;">add_box</span></p>
        <p>3.點選 “新增”</p>
        <div class="arrow-down"></div>
        <img class="modal-logo" src="/img/icons/icon-96x96.png">

      </div>

    </div>
  </div>
</template>

<script>
import { NavBar, Tabbar, TabbarItem } from "vant";

export default {
  name: "app",
  components: {
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },

  data() {
    return {
      title: "", // 導航標題(子組件傳遞過來)
      navViewShow: true, // 導航顯示或隱藏
      active: 0, //tabbar 顯示被選
      toHome: false, //首頁置頂
      bootom: true, //顯示下方按鈕
    };
  },

  created() {
    //掛維護時打開
    //window.location.replace("https://book168.sms104.com/miss20120919/in.html");
    var env = process.env.VUE_APP_ENV;

    //取得裝置類型
    var deviceInfo = this.getDeviceInfo();

    //大網轉址處理
    var url = "";
    if (!deviceInfo.isMobile) {
      url = "http://www.a383.com";
      if (env == "prod") {
        window.location.replace(url);
      }
    }
    //ALN
    else {
      if (window.location.origin.indexOf("/m.") == -1) {
        //TODO 測試先取消轉址, 測完再恢復
        //window.location.replace("https://m.a383.com");
      }
    }

    // url 由 http 轉到 https
    // 由於 S3 測試站 (dev-mb),不支援 https, 因此不轉, 維持 http  
    var https_url = "";
    url = location.href;
    var ary = url.split("/");
    if (ary[0] != "https:") {
      https_url = url.replace("http", "https");
      if (env == "prod" && url.indexOf("dev-mb") && url.indexOf("localhost") == -1 && url.indexOf("192.168.1.135") == -1) {
        console.log("http to https:", https_url)
        location.href = https_url;
      } else (
        console.log("dev-mb http :", url)
      )
    }
    //警示頁隱藏下方BAR
    if(this.$route.fullPath == "/homewarning") {
      this.bootom = false;
    } else {
      this.bootom = true;
    }

    // 獲取下標
    var itemIndex = parseInt(window.localStorage.getItem("itemIndex"));
    //var paths = ["/home", "/category", "/buy", "/nav", "/mine"];
    if (!isNaN(itemIndex)) {
      // 從0開始的
      if (this.$route.fullPath == "/home") {
        this.active = 0;
      } else {
        this.active = itemIndex;
      }
    } else {
      this.active = 0;
    }
  },
  methods: {
    getTitle: function (title) {
      this.title = title;
    },

    getShow: function (temp) {
      this.navViewShow = temp;
    },

    setActive: function (value) {
      this.active = value;
    },
    setBootom: function (value) {
      this.bootom = value;
    },

    onClickTabbarItem: function () {
      // 保存
      window.localStorage.setItem("itemIndex", this.active);

      //var itemIndex = parseInt(window.localStorage.getItem("itemIndex"));
    },

    chkMobile: function () {
      //判斷是否手機端訪問
      var result = false;
      var userAgentInfo = navigator.userAgent.toLowerCase();
      //alert(userAgentInfo)
      var Agents = [
        "android",
        "iphone",
        "symbianos",
        "windows phone",
        "ipad",
        "ipod",
      ];
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) >= 0) {
          result = true; //是手機
        }
      }
      return result;
    },

    getDeviceInfo: function () {
      //取得裝置類型
      var isMobile = false;
      var isIOS = false;
      var deviceType = "";
      var deviceInfo = new Object();
      var userAgentInfo = navigator.userAgent.toLowerCase();
      var Agents = [
        "android",
        "iphone",
        "symbianos",
        "windows phone",
        "ipad",
        "ipod",
      ];
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) >= 0) {
          deviceType = Agents[v];
          isMobile = true;
        }
      }

      // iPad 處理
      isIOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

      deviceInfo["isMobile"] = isMobile || isIOS;
      deviceInfo["isIOS"] = isIOS;
      deviceInfo["deviceType"] = deviceType;
      return deviceInfo;
    },

    scrollToTop: function () {
      window.scrollTo(0, 0);
      this.toHome = !this.toHome;
    },
  }, //methods
};
</script>

<style>
body {
  background: #f2f2f2;
  font-size: 1rem;
  font-weight: 700;
}

#app {
  overflow:auto;
  overflow-x: hidden;
  background: #f2f2f2;
  font-family: "Arial", "Helvetica", "sans-serif";
}

/* .van-nav-bar {
  height: 2.8125rem;
  background: white;
  font-size: 1rem;
  font-weight: normal;
  line-height: 2.8125rem;
  text-align: center;
}
.van-nav-bar .van-nav-bar__title {
  color: white;
}

.van-nav-bar .van-icon {
  color: white;
  font-size: 1rem;
} */

/* transition name='xxxx'
	* xxx-enter (進入)過渡開始狀態
	* xxx-enter-to (進入)過渡結束的狀態
	* xxx-enter-active (進入)過渡時間、延遲、曲線
	* xxx-leave (離開)過渡開始狀態
	* xxx-leave-to (離開)過渡結束的狀態
	* xxx-leave-active (離開)過渡時間、延遲、曲線
	*/
/* 從右邊到左邊 */
.slide-to-left-enter-active,
.slide-to-left-leave-active {
  transition: all 2s;
}

.slide-to-left-enter,
.slide-to-left-leave-to {
  transform: translateX(100%);
}

/* 從底部到頂部 */
.slide-to-top-enter-active,
.slide-to-top-leave-active {
  transition: all 2s;
}

.slide-to-top-enter,
.slide-to-top-leave-to {
  transform: translateY(100%);
}

.vjs-poster {
  background-size: cover;
}

/* .vjs-poster {
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}  */

/* .van-toast {
  background-color: none;
}  */

/* .van-tabbar .van-tabbar-item {
   font-size: 16px; 
} */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.7);
}

/* Modal Content */
.modal-content {
  background-color: #3b3b3b;
  margin: auto;
  padding: 42px 20px 0px 20px;
  border: 1px solid #8880;
  width: 35vh;
  height: 35vh;
  text-shadow: none;
  color: white;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
}
.modal-content>h4 {
  line-height: 2.1rem;
}
.modal-content>p,.modal-content>p>span {
  line-height: 2.1rem;
}

/* The Close Button */
.close {
  color: #a19f9f;
  opacity: 1;
  text-shadow: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: 500;
}

.iosModalClose:hover,
.iosModalClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #3b3b3b;
  position: absolute;
  bottom: -9px;
  left: calc(50% - 10px);
}

.modal-logo {
  width: 60px;
  position: absolute;
  top: -24px;
  border-radius: 12px;
}
</style>
